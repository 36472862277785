import { NavLink } from "react-router-dom";
import EmojiScore from "../Utils/EmojiScore";

import { Zap } from "react-feather";

import "./Teaser.scss";

const Teaser = ({ isAuthenticated, isVerified, loginWithRedirect }) => {
  return (
    <div className="Teaser">
      <EmojiScore score={"teaser"} />
      <div className="text">
        <p>
          {isAuthenticated ? (
            !isVerified ? (
              <>
                <NavLink to="/profile" className="underline">
                  Verify your account
                </NavLink>{" "}
                to start seeing your activity and recommendations.
              </>
            ) : (
              <>
                Rate horror films and shows to get recommendations!
                <br />
                <NavLink to="/quickrate" className="btn small">
                  <Zap /> Quick rate
                </NavLink>
              </>
            )
          ) : (
            <>
              <a
                href="/profile"
                onClick={(e) => {
                  e.preventDefault();
                  loginWithRedirect({
                    authorizationParams: {
                      screen_hint: "signup",
                    },
                  });
                }}
                className="underline"
              >
                Sign up
              </a>{" "}
              or{" "}
              <a
                href="/profile"
                onClick={(e) => {
                  e.preventDefault();
                  loginWithRedirect();
                }}
                className="underline"
              >
                log in
              </a>{" "}
              to unlock personalized recommendations!
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default Teaser;
