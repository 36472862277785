import { useContext, useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { AppStatusContext } from "../components/Utils/AppStatus";
import UserContext from "../components/Auth/UserContext";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import "../styles/_tabs.scss";

import HeroItem from "../components/Hero/Item";
import Posters from "../components/Items/Posters";
import Percentage from "../components/Blocks/Percentage";
import ItemDetails from "../components/Blocks/ItemDetails";

const Person = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const { appLoading, setAppLoading } = useContext(AppStatusContext);

  // Tabs
  const tabsRef = useRef(null);
  const [tabIndex, setTabIndex] = useState(0);
  function handleTabSelect(index) {
    setTabIndex(index);
    // tabsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  const [person, setPerson] = useState({});

  const { user, isAuthenticated, isLoading } = useContext(UserContext);
  const [userStats, setUserStats] = useState(false);

  const url =
    process.env.REACT_APP_API_URL +
    "/person/?token=" +
    process.env.REACT_APP_API_KEY +
    "&id=" +
    id;

  // Load person
  useEffect(() => {
    fetch(url, { cache: "force-cache" })
      .then((response) => response.json())
      .then((data) => {
        setPerson(data.person);
        setAppLoading(false);
      });
  }, [url, setAppLoading]);

  // Load user stats
  useEffect(() => {
    if (person && !isLoading && isAuthenticated && user) {
      const userUrl =
        process.env.REACT_APP_API_URL +
        "/user/person/?token=" +
        process.env.REACT_APP_API_KEY +
        "&id=" +
        id +
        "&user=" +
        user.sub;

      fetch(userUrl, { cache: "default" })
        .then((response) => response.json())
        .then((data) => {
          if (data.status !== "error") {
            setUserStats(data.results);
          }
        })
        .catch(function (error) {
          setUserStats({});
        });
    } else {
      setUserStats({});
    }
  }, [isAuthenticated, pathname]);

  // Reset on path change
  useEffect(() => {
    setPerson({});
  }, [pathname]);

  return (
    <section id="Person">
      {!appLoading && person?.name && (
        <Helmet>
          <title>{person.name} &middot; Horror.ar</title>
          <link
            rel="canonical"
            href={`${process.env.REACT_APP_URL}/person/${id}`}
          />
        </Helmet>
      )}
      <HeroItem item={person} isLoading={appLoading} type="small" />
      {!appLoading && person && person.credits && person.credits.items && (
        <>
          {!isLoading && isAuthenticated && <Percentage stats={userStats} />}
          <hr className="invisible" ref={tabsRef} />
          <Tabs selectedIndex={tabIndex} onSelect={handleTabSelect}>
            <TabList>
              {Object.entries(person.credits.items).map(([k, v], i) => (
                <Tab key={i}>
                  {k} {/* <small>{v.length}</small> */}
                </Tab>
              ))}
            </TabList>

            <SwipeableViews index={tabIndex} onChangeIndex={handleTabSelect}>
              {Object.entries(person.credits.items).map(([k, v], i) => (
                <TabPanel key={i}>
                  <Posters items={v} sortable={true} inTab={true} />
                </TabPanel>
              ))}
            </SwipeableViews>
          </Tabs>

          <h2 className="cSubtitle">Details</h2>
          <ItemDetails item={person} />
        </>
      )}
    </section>
  );
};

export default Person;
