import { useState } from "react";
import Modal from "react-modal";
import ModalHeader from "./ModalHeader";

import "./Streaming.scss";

function Streaming({ item, country, countryName }) {
  const streamingServices = item.watch || [];

  const [modalOpen, setModalOpen] = useState(false);
  if (streamingServices[country] && streamingServices[country].length !== 0) {
    const categories = ["free", "flatrate", "buy", "rent"];
    const optionsMap = {};
    let totalProviders = 0;
    const getFlagEmoji = (countryCode) =>
      String.fromCodePoint(
        ...[...countryCode.toUpperCase()].map((x) => 0x1f1a5 + x.charCodeAt())
      );

    categories.forEach((category) => {
      if (
        streamingServices[country][category] &&
        streamingServices[country][category].length > 0
      ) {
        optionsMap[category] = streamingServices[country][category].map(
          (option) => ({
            logo_path: option.logo_path,
            provider_id: option.provider_id,
            provider_name: option.provider_name,
            link: streamingServices[country].link,
          })
        );
      }
    });

    // First 4 unique providers based on "provider_name"
    const selectedProviders = categories
      .map((type) => optionsMap[type])
      .filter((provider) => provider && provider.length > 0)
      .flat()
      .reduce((uniqueProviders, provider) => {
        if (
          !uniqueProviders.find(
            (existingProvider) =>
              existingProvider.provider_name === provider.provider_name
          )
        ) {
          uniqueProviders.push(provider);
        }
        totalProviders = uniqueProviders.length;
        return uniqueProviders;
      }, [])
      .slice(0, 4);

    return (
      <>
        <div className="LaunchStreaming HorizontalScroll">
          <ul>
            <li>
              <button
                onClick={() => {
                  setModalOpen(true);
                }}
                className="watch subtle small"
              >
                <span className="emoji">{getFlagEmoji(country)}</span>
                Watch
              </button>
            </li>
            {selectedProviders.map((service, index) => (
              <li key={index}>
                <a
                  className="btn subtle small"
                  href={service.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`https://image.tmdb.org/t/p/original${service.logo_path}`}
                    alt={service.provider_name}
                  />

                  {service.provider_name.split(" ")[0]}
                </a>
              </li>
            ))}
            {totalProviders > 4 && (
              <li>
                <button
                  onClick={() => {
                    setModalOpen(true);
                  }}
                  className="subtle  small"
                >
                  +{totalProviders - 4}
                </button>
              </li>
            )}
          </ul>
        </div>

        <Modal
          preventScroll={true}
          closeTimeoutMS={399}
          ariaHideApp={false}
          isOpen={modalOpen}
          onRequestClose={() => {
            setModalOpen(false);
          }}
          className="Streaming"
        >
          <ModalHeader
            title={"Watch in " + countryName}
            poster={item.poster}
            subtitle={item.title}
            onClose={() => {
              setModalOpen(false);
            }}
          />
          {Object.keys(optionsMap).map((category) => (
            <ul key={category}>
              <li className="category tSubtitle">
                {category === "free" || category === "flatrate"
                  ? "Watch Online"
                  : category.charAt(0).toUpperCase() + category.slice(1)}
              </li>
              {optionsMap[category].map((service, index) => (
                <li key={index} className={category}>
                  <a href={service.link} target="_blank" rel="noreferrer">
                    <img
                      src={`https://image.tmdb.org/t/p/original${service.logo_path}`}
                      alt={service.provider_name}
                    />
                    <p>
                      <small>
                        {category === "free" || category === "flatrate"
                          ? "Watch"
                          : category.charAt(0).toUpperCase() +
                            category.slice(1)}
                      </small>
                      {service.provider_name.split(" ")[0]}
                    </p>
                  </a>
                </li>
              ))}
            </ul>
          ))}
        </Modal>
      </>
    );
  }
}

export default Streaming;
