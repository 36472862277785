import React from "react";
import { NavLink } from "react-router-dom";

import * as dayjs from "dayjs";
import * as relativeTime from "dayjs/plugin/relativeTime";

import "./ItemDetails.scss";
dayjs.extend(relativeTime);

export default function ItemDetails(props) {
  const item = props.item;
  const dateFormat = "MMMM DD, YYYY";

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DisplayNames
  // const regionNamesInEnglish = new Intl.DisplayNames(["en"], {    type: "region" });
  const languageNames = new Intl.DisplayNames(["en"], { type: "language" });

  return (
    <dl className={"ItemDetails " + item.type}>
      {item.aliases && (
        <>
          <dt className="tSmall">Aliases</dt>
          <dd>
            <ul>
              {item.aliases.map((item, i) => (
                <li key={i}>{item.name}</li>
              ))}
            </ul>
          </dd>
        </>
      )}
      {item.type !== "person" && (
        <>
          <dt className="tSmall">Release</dt>
          <dd>
            {item.year || item.date ? (
              item.date ? (
                <>
                  {dayjs(item.date).format(dateFormat)}{" "}
                  <span className="tSmall">
                    (
                    {dayjs().isSame(dayjs(item.date), "day")
                      ? "Today"
                      : dayjs().diff(item.date, "days") === 0
                      ? dayjs().isAfter(dayjs(item.date), "day")
                        ? "Yesterday"
                        : "Tomorrow"
                      : dayjs().to(dayjs(item.date))}
                    )
                  </span>
                </>
              ) : (
                item.year
              )
            ) : !item.production || item.production === "Released" ? (
              "To be confirmed"
            ) : (
              item.production
            )}
          </dd>
          {item.end && (
            <>
              <dt className="tSmall">End</dt>
              <dd>
                {dayjs(item.end).format(dateFormat)} (
                {dayjs().to(dayjs(item.end))})
              </dd>
            </>
          )}
          {item.production && (
            <>
              <dt className="tSmall">Status</dt>
              <dd>
                {item.production === "Released" && !item.released
                  ? "Coming soon"
                  : item.production}
              </dd>
            </>
          )}

          {item.rating && (
            <>
              <dt className="tSmall">Rating</dt>
              <dd>{item.rating}</dd>
            </>
          )}
        </>
      )}

      {item.origins && (
        <>
          <dt className="tSmall">Origin</dt>
          <dd>
            <ul>
              {item.origins.map((item, i, row) => (
                <li key={i}>
                  <NavLink to={"/country/" + item.name}>{item.name}</NavLink>
                </li>
              ))}
            </ul>
          </dd>
        </>
      )}
      {item.language && (
        <>
          <dt className="tSmall">Language</dt>
          <dd>{languageNames.of(item.language)}</dd>
        </>
      )}

      {item.type === "person" && (
        <>
          {item.origin && (
            <>
              <dt className="tSmall">From</dt>
              <dd>{item.origin}</dd>
            </>
          )}
          {item.birthday && (
            <>
              <dt className="tSmall">Born</dt>
              <dd> {dayjs(item.birthday).format("LL")}</dd>
            </>
          )}
          {item.deathday && (
            <>
              <dt className="tSmall">Died</dt>
              <dd> {dayjs(item.deathday).format("LL")}</dd>
            </>
          )}
        </>
      )}

      {item.networks && (
        <>
          <dt className="tSmall">Networks</dt>
          <dd>
            <ul>
              {item.networks.map((item, i) => (
                <li key={i}>{item.name}</li>
              ))}
            </ul>
          </dd>
        </>
      )}

      {item.producers && (
        <>
          <dt className="tSmall">Production companies</dt>
          <dd>
            <ul>
              {item.producers.map((item, i) => (
                <li key={i}>{item.name}</li>
              ))}
            </ul>
          </dd>
        </>
      )}

      {item.links && (
        <>
          <dt className="tSmall">More info</dt>
          <dd>
            <ul>
              {Object.entries(item.links).map(([key, value]) => (
                <li key={key}>
                  <a href={value} target="_blank" rel="noreferrer">
                    {key}
                  </a>
                </li>
              ))}
            </ul>
          </dd>
        </>
      )}
    </dl>
  );
}
