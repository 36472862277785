import { useContext, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { AppStatusContext } from "../components/Utils/AppStatus";
import UserContext from "../components/Auth/UserContext";

import HeroItem from "../components/Hero/Item";
import Posters from "../components/Items/Posters";
import Percentage from "../components/Blocks/Percentage";
import ItemActions from "../components/Blocks/ItemActions";

const Collection = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const isList = pathname.includes("/list");
  const section = isList ? "List" : "Collection";

  const [itemsLoading, setItemsLoading] = useState(true);
  const [collection, setCollection] = useState({});
  const [items, setItems] = useState({});

  const { setAppLoading } = useContext(AppStatusContext);

  const { user, isAuthenticated, isLoading } = useContext(UserContext);
  const [userStats, setUserStats] = useState(false);

  const url =
    process.env.REACT_APP_API_URL +
    "/" +
    (isList ? "list" : "collection") +
    "/?token=" +
    process.env.REACT_APP_API_KEY +
    "&id=" +
    id;

  // Load items
  useEffect(() => {
    fetch(url, { cache: "default" })
      .then((response) => response.json())
      .then((data) => {
        setCollection(data.collection ?? data.list);
        setItems(isList ? data.list.items.items : data.collection.items.items);
        setItemsLoading(false);
        setAppLoading(false);
      });
  }, [url, isList]);

  // Load user stats
  useEffect(() => {
    if (items && !isLoading && isAuthenticated && user) {
      const userUrl =
        process.env.REACT_APP_API_URL +
        "/user/collection/?token=" +
        process.env.REACT_APP_API_KEY +
        "&type=" +
        (isList ? "list" : "collection") +
        "&id=" +
        id +
        "&user=" +
        user.sub;

      fetch(userUrl, { cache: "default" })
        .then((response) => response.json())
        .then((data) => {
          if (data.status !== "error") {
            setUserStats(data.results);
            setAppLoading(false);
          }
        })
        .catch(function (error) {
          setUserStats({});
        });
    } else {
      setUserStats({});
    }
  }, [isAuthenticated, pathname]);

  // Reset on path change
  useEffect(() => {
    setCollection({});
    setItems({});
    setItemsLoading(true);
    setUserStats(false);
  }, [pathname]);

  return (
    <section id="Collection" className={itemsLoading ? "Loading" : "Done"}>
      {!isLoading && collection?.name && (
        <Helmet>
          <title>
            {section}: {collection.name} &middot; Horror.ar
          </title>
        </Helmet>
      )}

      {!itemsLoading && items && isList && (
        <ItemActions type="list" item={collection} userStats={userStats} />
      )}

      <HeroItem item={collection} isLoading={itemsLoading} type="small" />

      {!itemsLoading && items && (
        <>
          {!isLoading && isAuthenticated && <Percentage stats={userStats} />}
          <Posters items={items} sortable={true} noParent={true} />
        </>
      )}
    </section>
  );
};

export default Collection;
