import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

import UserContext from "../Auth/UserContext";
import { User, Home, Search, Menu, Sliders, ArrowLeft } from "react-feather";
import ImgCdn from "../Utils/ImgCdn";
import { ReactComponent as Logo } from "../../img/logo-h.svg";

import "./Nav.scss";

export default function Footer() {
  const { pathname } = useLocation();
  const { username } = useParams();

  // Handle scroll
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 8) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Handle menu
  const toggleMenu = () => {
    const bodyClasses = document.body.classList;
    if (document.body.classList.contains("menuOpen")) {
      bodyClasses.remove("menuOpen");
    } else {
      bodyClasses.add("menuOpen");
    }
  };

  // User stuff
  const { user, isAuthenticated, isLoading, userProfile } =
    useContext(UserContext);

  // Set avatar depending on user profile
  const [userIcon, setUserIcon] = useState("");

  useEffect(() => {
    !isLoading && isAuthenticated && userProfile
      ? setUserIcon(
          <ImgCdn
            src={userProfile.profile.picture}
            alt={userProfile.profile.username}
            t="person_sm"
            loading="lazy"
            className="avatar"
          />
        )
      : setUserIcon(<User />);
  }, [userProfile, isAuthenticated, isLoading]);

  // Go back
  const navigate = useNavigate();
  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <nav id="nav" data-scrolled={scrolled}>
      <div>
        <NavLink to="/" className="wide logo">
          <Logo />
        </NavLink>
        <a
          href="/"
          className={"back " + (pathname === "/" ? "hidden" : "visible")}
          onClick={handleBack}
          aria-label="Go back"
        >
          <ArrowLeft />
        </a>
        <NavLink to="/">
          <Home /> <span>Discover</span>
        </NavLink>
        <NavLink to="/genres" className="wide">
          <span>Genres</span>
        </NavLink>
        <NavLink to="/lists" className="wide">
          <span>Lists</span>
        </NavLink>

        <NavLink to="/items/reviews" className="wide">
          <span>Reviews</span>
        </NavLink>
        <NavLink
          to="/search"
          className={
            "search" +
            (pathname.startsWith("/country/") || pathname.startsWith("/year/")
              ? " active"
              : "")
          }
        >
          <Search />
          <span>Search</span>
        </NavLink>
        {!isLoading && isAuthenticated ? (
          <NavLink
            to={"/user/" + user.username}
            className={
              "profile narrow " +
              (pathname.startsWith("/profile") && username === user.username
                ? "active"
                : "")
            }
          >
            {userIcon}
            <span>Profile</span>
          </NavLink>
        ) : (
          <NavLink
            to="/profile"
            className={
              "profile narrow " + (pathname.startsWith("/profile") && "active")
            }
          >
            {userIcon}
            <span>Profile</span>
          </NavLink>
        )}
        <button className="menu" onClick={toggleMenu} aria-label="Open menu">
          {pathname.startsWith("/search") ||
          pathname.startsWith("/country") ||
          pathname.startsWith("/year") ? (
            <Sliders className="buttonSliders" />
          ) : (
            <Menu className="buttonMenu" />
          )}
          <span>Menu</span>
        </button>
      </div>
    </nav>
  );
}
