import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Blurhash } from "react-blurhash";

import ImgCdn from "../Utils/ImgCdn";

import "./Hero.scss";
import "./Intro.scss";

const introText =
  "Discover, collect, and review your favorite horror films & shows with like-minded fans.";

export default function HeroIntro(props) {
  const [featured, setFeatured] = useState(false);

  // Fetch items when component mounts

  // Define random featured item
  useEffect(() => {
    if (props.items && props.items.length) {
      let featuredItem = props.items
        .filter((item) => item.cover !== null)
        .sort(() => Math.random() - 0.5)
        .slice(0, 1)[0];
      setFeatured(featuredItem);
    }
  }, [props?.items]);

  // Hero class
  const classList = [
    "Hero",
    "Intro",
    props.className,
    featured && featured.cover && "cover",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div className={classList}>
      {featured && featured.cover && (
        <NavLink to={"/item/" + featured.id} className="item">
          {featured.title.length > 100
            ? featured.title.slice(0, 97) + "..."
            : featured.title}
        </NavLink>
      )}
      <div className="text">
        <h1 className="tTitle">{props.text ?? introText}</h1>
      </div>
      {featured && featured.cover ? (
        <figure className="media blurhash">
          {featured.cover_blurhash && (
            <Blurhash hash={featured.cover_blurhash} width={720} height={405} />
          )}
          <ImgCdn
            src={featured.cover}
            alt={featured.title}
            width={720}
            height={405}
            t="cover_lg"
          />
        </figure>
      ) : (
        <figure className="media">
          <div className="imgPlaceholder"></div>
        </figure>
      )}
    </div>
  );
}
