import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import "../../styles/__Fullscreen.scss";

const imgs = ["scary-stories", "bates-motel", "hill-house"];

function Login() {
  const location = useLocation();
  const { loginWithRedirect } = useAuth0();
  const rand = Math.floor(Math.random() * imgs.length);

  return (
    <>
      <Helmet>
        <title>Log in or Sign up &middot; Horror.ar</title>
      </Helmet>

      <div className="text">
        <h1 className="tTitle">Enter if you dare</h1>
        <p>
          Sign in or create an account to rate, review and get personalized
          horror recomendations.
        </p>
        <p className="buttons">
          <button
            onClick={() =>
              loginWithRedirect({
                prompt: "login",
                appState: { target: location.pathname },
              })
            }
          >
            Log In
          </button>
          or
          <button
            onClick={() =>
              loginWithRedirect({
                authorizationParams: {
                  screen_hint: "signup",
                },
              })
            }
          >
            Sign Up
          </button>
        </p>
      </div>
      <img src={"/img/login/" + imgs[rand] + ".jpg"} alt="" />
    </>
  );
}

export default Login;
