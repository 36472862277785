import React, { useEffect, useState } from "react";
import AppContext from "./AppContext";

function AppProvider({ children }) {
  // Load genres

  const [genresLoading, setGenresLoading] = useState(true);
  const [genres, setGenres] = useState({});

  const url =
    process.env.REACT_APP_API_URL +
    "/genres/?token=" +
    process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const cachedGenres = localStorage.getItem("genres");
    const expirationTime = localStorage.getItem("genres_expiration_time");
    const currentTime = new Date().getTime();

    if (cachedGenres && expirationTime && currentTime < expirationTime) {
      setGenres(JSON.parse(cachedGenres));
      setGenresLoading(false);
    } else {
      fetch(url, { cache: "force-cache" })
        .then((response) => response.json())
        .then((data) => {
          let genres = data.genres;
          localStorage.setItem("genres", JSON.stringify(genres));

          // Set expiration time to 24 hours from now
          const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000;
          localStorage.setItem("genres_expiration_time", expirationTime);

          setGenres(genres);
          setGenresLoading(false);
        })
        .catch((error) => {
          console.error(error);
          // Handle error here, e.g. set error state or display error message to user
          setGenresLoading(false);
        });
    }
  }, [url]);

  return (
    <AppContext.Provider value={{ genres, genresLoading }}>
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;
