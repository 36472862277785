import React, { useState } from "react";
import BlurhashImage from "../Utils/BlurhashImage";
import ModalImage from "./ModalImage";
import { MoreHorizontal } from "react-feather";

import poster from "../../img/poster_md.png";
import * as dayjs from "dayjs";

import "./ItemSeasons.scss";

function ShowDescription({ description }) {
  //Descriptions
  const n = 180; // Maximum characters before truncating
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  return (
    <div className="description">
      <p>
        {showFullDescription || description.length <= n
          ? description
          : description.slice(0, n) + "..."}
      </p>
      {description.length > n && !showFullDescription && (
        <button className="icon showMore" onClick={toggleDescription}>
          <MoreHorizontal />
        </button>
      )}
    </div>
  );
}

export default function ItemSeasons(props) {
  const item = props.item;
  const dateFormat = "MMM D, YYYY";

  // Modals
  const [currentModal, setCurrentModal] = useState(false);

  return (
    <div className="Seasons">
      {item.seasons_detail &&
        item.seasons_detail.map((s, i) => (
          <article key={i}>
            <header>
              <figure className="blurhash" key={i}>
                {s.poster ? (
                  <>
                    <BlurhashImage
                      src={s.poster}
                      alt={"Season " + s.number}
                      t="poster_md"
                      blurhash={s.poster_blurhash ?? null}
                      width={120}
                      height={180}
                      onClick={() => {
                        setCurrentModal("Season" + s.number);
                      }}
                    />
                    <ModalImage
                      isOpen={currentModal === "Season" + s.number}
                      image={{
                        src: s.poster,
                        alt: "Season " + s.number,
                        blurhash: s.poster_blurhash ?? null,
                        width: 600,
                        height: 900,
                      }}
                      onClick={() => {
                        setCurrentModal(false);
                      }}
                    />
                  </>
                ) : (
                  <img src={poster} alt="" width="120" height="180" />
                )}
              </figure>
              <hgroup>
                <h2 className="tMedium">
                  {s.name
                    ? s.name.startsWith("Season ")
                      ? s.name
                      : `Season ${s.number}: ${s.name}`
                    : `Season ${s.number}`}
                </h2>
                {s.episodes && s.episodes > 1 && <h3>{s.episodes} episodes</h3>}
                <p className="tSmall">
                  {s.date ? dayjs(s.date).format(dateFormat) : "TBA"}
                  {s.date && (
                    <>
                      {" "}
                      &mdash; {s.end ? dayjs(s.end).format(dateFormat) : "TBA"}
                    </>
                  )}
                </p>
              </hgroup>
            </header>
            {s.description && <ShowDescription description={s.description} />}
          </article>
        ))}
    </div>
  );
}
