import { MoreHorizontal } from "react-feather";

import Review from "./Review";

import "./Reviews.scss";

export default function Reviews(props) {
  return (
    <div className="Reviews">
      {props.item.reviews.top && (
        <>
          <div className="items">
            {props.item.reviews.top.map((item, i) => (
              <Review
                key={i}
                review={item}
                item={props.item}
                handleItemData={props.handleItemData}
                userData={props.userData}
                handleUserData={props.handleUserData}
              />
            ))}
          </div>
          {props.item.reviews.total > props.item.reviews.top.length && (
            <button className="icon showMore">
              <MoreHorizontal />
              <small className="tSmall">
                {props.item.reviews.total - props.item.reviews.top.length} more
              </small>
            </button>
          )}
        </>
      )}
    </div>
  );
}
