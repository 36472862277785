import { useState } from "react";
import { MoreHorizontal } from "react-feather";

import "./ExternalReviews.scss";

export default function ExternalReviews(props) {
  const reviews = props.reviews;
  const [showMore, setShowMore] = useState("");

  const getDomain = (url) => {
    const parsedUrl = new URL(url);
    let domain = parsedUrl.hostname;
    if (domain.startsWith("www.")) {
      domain = domain.substring(4); // Remove "www." from the beginning
    }
    return domain;
  };

  return (
    <div
      className={"ExternalReviews " + (showMore && " More")}
      style={{ "--total": reviews.length }}
    >
      <ul>
        {reviews.map((review) => (
          <li key={review.id}>
            <a href={review.url} target="_blank" rel="noopener noreferrer">
              <p className="author">
                <img
                  src={`https://www.google.com/s2/favicons?domain=${getDomain(
                    review.url
                  )}&sz=24`}
                  srcSet={`https://www.google.com/s2/favicons?domain=${getDomain(
                    review.url
                  )}&sz=48 2x`}
                  alt=""
                  width="24"
                  height="24"
                  loading="lazy"
                />
                <strong>{review.title}</strong>
                <small>{getDomain(review.url)}</small>
              </p>
              <p className="description">{review.description}</p>
            </a>
          </li>
        ))}
      </ul>
      {props.limit && props.limit < reviews.length && (
        <button
          className="icon showMore"
          onClick={() => {
            setShowMore(1);
          }}
        >
          <MoreHorizontal />
          <small className="tSmall">{reviews.length - props.limit} more</small>
        </button>
      )}
    </div>
  );
}
