import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

import UserContext from "../Auth/UserContext";
import ImgCdn from "../Utils/ImgCdn";
import Loader from "../Utils/Loader";
import EmojiScore from "../Utils/EmojiScore";
import { toast } from "react-toastify";

import avatar from "../../img/person_lg.png";

import "./RateItem.scss";

const ratings = ["awful", "bad", "meh", "good", "great"];

export default function RateItem(props) {
  const location = useLocation();

  const {
    loginWithRedirect,
    userProfile,
    isAuthenticated,
    getAccessTokenSilently,
  } = useContext(UserContext);
  const [message, setMessage] = useState(null);
  const [voting, setVoting] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  // Voted?
  useEffect(() => {
    if (props.userData && null !== props.userData.score) {
      const score = parseInt(props.userData.score);
      setSelectedIndex(score);
      setMessage(score >= 0 ? ratings[score] : null);
    } else {
      setMessage(null);
    }
  }, [props]);

  const handleRating = async (index) => {
    const score = index === selectedIndex ? -1 : index;
    setSelectedIndex(score);
    setVoting(true);
    if (isAuthenticated) {
      const access_token = await getAccessTokenSilently({
        ignoreCache: true,
      });

      const formData = new URLSearchParams();
      formData.append("access_token", access_token);
      formData.append("item", props.item.id);
      formData.append("score", score);
      fetch(
        process.env.REACT_APP_API_URL +
          "/user/actions/?action=rateItem&token=" +
          process.env.REACT_APP_API_KEY,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.status !== "error") {
            // Update item data
            //props.itemHandler("score", props.item)
            fetch(
              process.env.REACT_APP_API_URL +
                "/itemscore/?id=" +
                props.item.id +
                "&token=" +
                process.env.REACT_APP_API_KEY
            )
              .then((response) => response.json())
              .then((data) => {
                props.itemHandler("score", data.item.score);
                props.itemHandler("votes", data.item.votes);
              })
              .catch((error) => {
                console.error(error);
              });

            // Update user data
            props.handler("score", score >= 0 ? score : null);

            if (score >= 0) {
              const userLists = [
                ...props.userData.lists,
                {
                  list: "watched",
                  list_id: null,
                },
              ];
              const updatedLists = userLists.filter(
                (item) => item.list !== "watchlist"
              );
              props.handler("lists", updatedLists);
            }
          } else {
            if (
              res.message.startsWith(
                "Expiration Time (exp) claim error in the token"
              )
            ) {
              toast.error("Your session has expired.");
              setTimeout(function () {
                loginWithRedirect({
                  prompt: "login",
                  appState: { target: location.pathname },
                });
              });
            }
          }
          setVoting(false);
        });
    }
  };

  return (
    <div
      className="RateItem"
      data-voting={voting ? "true" : "false"}
      data-voted={selectedIndex >= 0 ? "true" : "false"}
    >
      <ul className="ratings userContent">
        {[...Array(5)].map((_, index) => (
          <li key={index}>
            <button
              data-selected={index === selectedIndex}
              onClick={() => handleRating(index)}
            >
              {/*
              <span className="emoji" aria-label={rating.label}>
                {rating.emoji}
              </span>
              <small>{rating.label}</small>
        */}
              <EmojiScore score={index} />
              <small>{ratings[index]}</small>
            </button>
          </li>
        ))}
      </ul>
      <div className="user">
        <figure className="avatar">
          {voting ? (
            <Loader />
          ) : userProfile.profile.picture ? (
            <ImgCdn
              src={userProfile.profile.picture}
              alt={userProfile.profile.username}
              t="person_sm"
              loading="lazy"
              className="avatar"
            />
          ) : (
            <img src={avatar} alt="" />
          )}
        </figure>
        <p>
          <strong>{props.item.title}</strong> is
          {message ? (
            <>
              {" "}
              <em>{message}</em>.
            </>
          ) : (
            "..."
          )}
        </p>
      </div>
    </div>
  );
}
