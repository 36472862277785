import React, { useEffect, useState } from "react";
import { useConnector } from "react-instantsearch-hooks-web";
import connectRange from "instantsearch.js/es/connectors/range/connectRange";

import RangeSlider from "react-range-slider-input";
import "./RangeSlider.scss";
//import "react-range-slider-input/dist/style.css";

function useRangeSlider(props) {
  return useConnector(connectRange, props);
}

export function CustomRangeSlider(props) {
  const { start, range, canRefine, refine } = useRangeSlider(props);
  const [values, setValues] = useState([range.min, range.max]);
  const { min: minRange, max: maxRange } = range;

  useEffect(() => {
    const [minStart, maxStart] = start;
    const minFinite = minStart === -Infinity ? minRange : minStart;
    const maxFinite = maxStart === Infinity ? maxRange : maxStart;
    setValues([Math.min(minFinite, maxRange), Math.max(minRange, maxFinite)]);
  }, [start, minRange, maxRange]);

  return (
    <div className="RangeSlider">
      {props.attribute && values && (
        <div className="label">
          <output>{values[0]}</output>
          <label className="tSmall">{props.attribute}</label>
          <output>{values[1]}</output>
        </div>
      )}
      <RangeSlider
        min={minRange}
        max={maxRange}
        step={1}
        value={values}
        onInput={(setValues, refine)}
        disabled={!canRefine}
      />
    </div>
  );
}
