import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import { AppStatusContext } from "../Utils/AppStatus";
import Header from "./Header";
import Footer from "./Footer";
import Nav from "./Nav";
import Menu from "../../views/Menu";

const Layout = (props) => {
  const { classNames } = useContext(AppStatusContext);
  const location = useLocation();

  // Window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleWindowResize = () => setWindowWidth(window.innerWidth);
  window.addEventListener("resize", handleWindowResize);

  return (
    <div id="app" className={classNames}>
      <Header />
      {location.pathname.startsWith("/search") ||
      location.pathname.startsWith("/country") ||
      location.pathname.startsWith("/year") ? (
        <>
          {props.children}
          {windowWidth > 840 && <Footer />}
        </>
      ) : (
        <>
          <main>
            {props.children}
            {windowWidth > 840 && <Footer />}
          </main>
          <Menu />
        </>
      )}
      <Nav />
    </div>
  );
};

export default Layout;
