import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Auth0Provider } from "@auth0/auth0-react";
import { Analytics } from "@vercel/analytics/react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { AppStatus } from "./components/Utils/AppStatus";
import AppProvider from "./components/Utils/AppProvider";
import UserProvider from "./components/Auth/UserProvider";
import Layout from "./components/Layout/Layout";
import Home from "./views/Home";
import Items from "./views/Items";
import Search from "./views/Search";
import Item from "./views/Item";
import Person from "./views/Person";
import Collection from "./views/Collection";
import Lists from "./views/Lists";
import Genre from "./views/Genre";
import Review from "./views/Review";
import Menu from "./views/Menu";
import Error from "./views/Error";
import Profile from "./views/Profile";
import User from "./views/User";
import Docs from "./views/Docs";
import Suggest from "./views/Suggest";
import QuickRate from "./views/QuickRate";

import "./styles/main.scss";

const container = document.getElementById("root");
const root = createRoot(container);

function Content() {
  const location = useLocation();

  return (
    <AppStatus>
      <Layout>
        <Routes location={location}>
          <Route index element={<Home />} />
          <Route path="/items/:key" element={<Items />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/user/:username" element={<User />} />
          <Route path="/user/:username/:view" element={<User />} />
          <Route path="/search" element={<Search />} />
          <Route path="/search/:query" element={<Search />} />
          <Route path="/country/:country" element={<Search />} />
          <Route path="/year/:year" element={<Search />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/item/:id" element={<Item />} />
          <Route path="/review/:id" element={<Review />} />
          <Route path="/person/:id" element={<Person />} />
          <Route path="/collection/:id" element={<Collection />} />
          <Route path="/list/:id" element={<Collection />} />
          <Route path="/lists" element={<Lists />} />
          <Route path="/genres" element={<Lists />} />
          <Route path="/genre/:id" element={<Genre />} />
          <Route path="/docs/:doc" element={<Docs />} />
          <Route path="/suggest" element={<Suggest />} />
          <Route path="/quickrate" element={<QuickRate />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Layout>
    </AppStatus>
  );
}

// Handle loader
var loadingElement = document.getElementById("loadingApp");

root.render(
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>
        Horror.ar &middot; Discover, collect and review your favorite horror
        films & shows
      </title>
    </Helmet>
    <BrowserRouter>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience:
            "https://" + process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
          scope: "openid profile email offline_access read:current_user",
        }}
      >
        <UserProvider>
          <AppProvider>
            <Content />
          </AppProvider>
        </UserProvider>
      </Auth0Provider>
      <Analytics />
    </BrowserRouter>
  </>
);

loadingElement.classList.add("done");
setTimeout(function () {
  loadingElement.parentNode.removeChild(loadingElement);
}, 600);

serviceWorkerRegistration.register();
