import { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { AppStatusContext } from "../components/Utils/AppStatus";
import UserContext from "../components/Auth/UserContext";

import { Zap, X, SkipForward } from "react-feather";
import AuthContent from "../components/Auth/AuthContent";
import RateItem from "../components/Blocks/RateItem";
import BlurhashImage from "../components/Utils/BlurhashImage";

import "./QuickRate.scss";

const QuickRate = () => {
  const { user, isLoading } = useContext(UserContext);
  const { setAppLoading } = useContext(AppStatusContext);

  /**
   * Get items
   */

  const [items, setItems] = useState({});

  useEffect(() => {
    if (!isLoading) {
      const url =
        process.env.REACT_APP_API_URL +
        "/quickrate/?token=" +
        process.env.REACT_APP_API_KEY +
        "&user=" +
        user.sub;

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          setItems(data.results);
          setAppLoading(false);
          // Preload images
          data.results.forEach((item) => {
            const img = new Image();
            img.src =
              "https://ik.imagekit.io/" +
              process.env.REACT_APP_CDN_ID +
              "/tr:n-poster/" +
              item.poster;
          });
        });
    }
  }, [isLoading, setAppLoading, user]);

  /**
   * Define current item
   */
  const [currentItem, setCurrentItem] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentStatus, setCurrentStatus] = useState("done");
  const [userData, setUserData] = useState({ lists: [], score: null });
  const [showText, setShowText] = useState(0);

  useEffect(() => {
    setCurrentStatus("waiting");
    setTimeout(() => {
      // Wait to show the selected score
      setCurrentStatus("voted");
      setTimeout(() => {
        // Wait until the item leaves the screen
        setCurrentStatus("new");
        setShowText(0);
        setUserData({ lists: [], score: -1 });
        setTimeout(() => {
          // Wait to show the next item

          setCurrentItem(currentIndex < 10 ? items[currentIndex] : {});
          setCurrentStatus("done");
        }, 400);
      }, 400);
    }, 400);
  }, [items, currentIndex]);

  /**
   * Handle voting
   */

  function handleVote(key, value) {
    setUserData((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });

    localStorage.removeItem("userProfile");
    localStorage.removeItem("userProfile_expiration_time");
    setCurrentIndex(currentIndex + 1);
  }

  return (
    <section id="QuickRate">
      <>
        <header className="intro">
          <h1 className="tMedium">
            <Zap /> Quick rate
          </h1>
          <NavLink to="/" className="exit">
            <X />
          </NavLink>
        </header>

        <AuthContent>
          {!isLoading && items && currentItem ? (
            <>
              {Object.keys(currentItem).length !== 0 ? (
                <>
                  <ul className="steps">
                    {[...Array(10)].map((_, index) => (
                      <li
                        key={index}
                        className={currentIndex > index ? "active" : null}
                      >
                        Item {index + 1}
                      </li>
                    ))}
                  </ul>
                  <div className="currentItem" data-status={currentStatus}>
                    <figure className="blurhash">
                      <BlurhashImage
                        src={currentItem.poster}
                        alt={currentItem.title}
                        t="poster"
                        blurhash={currentItem.poster_blurhash ?? null}
                        width={240}
                        height={360}
                      />
                    </figure>
                    <div className="content">
                      <header>
                        <h2 className="tSubtitle">
                          <strong>{currentItem.title}</strong>{" "}
                          <small>({currentItem.year})</small>
                        </h2>
                        {/*
                        <p
                          onClick={() =>
                            setShowText((prevShowText) => !prevShowText)
                          }
                          data-open={showText ? true : false}
                        >
                          {currentItem.description}
                        </p>
                        */}
                      </header>
                      <RateItem
                        item={currentItem}
                        handler={handleVote}
                        itemHandler={() => {}}
                        userData={userData}
                      />

                      <p className="buttons">
                        <NavLink
                          to={"/item/" + currentItem.id}
                          className="btn over"
                        >
                          View details
                        </NavLink>
                        <button
                          className="skip subtle"
                          onClick={() => setCurrentIndex(currentIndex + 1)}
                        >
                          Skip this item <SkipForward />
                        </button>
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <div className="end">
                  <Zap className="bigZap" />
                  <h2 className="tTitle">Done!</h2>
                  <NavLink to="/" className="btn">
                    Go back home
                  </NavLink>
                  <button
                    className="subtle"
                    onClick={() => window.location.reload()}
                  >
                    Rate again
                  </button>
                </div>
              )}
            </>
          ) : (
            <Zap className="bigZap" />
          )}
        </AuthContent>
      </>
    </section>
  );
};

export default QuickRate;
