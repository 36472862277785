import Modal from "react-modal";
import BlurhashImage from "../Utils/BlurhashImage";

export default function ModalImage(props) {
  return (
    <Modal
      preventScroll={true}
      closeTimeoutMS={399}
      ariaHideApp={false}
      isOpen={props.isOpen}
      onRequestClose={props.onClick}
      className="ModalImage poster"
    >
      <figure className="blurhash">
        <BlurhashImage
          src={props.image.src}
          alt={props.image.alt}
          t="poster"
          blurhash={props.image.blurhash ?? null}
          width={props.image.width}
          height={props.image.height}
          onClick={props.onClick}
        />
      </figure>
    </Modal>
  );
}
