import { Link } from "react-router-dom";

import "./Header.scss";
import { ReactComponent as Logo } from "../../img/logo.svg";

export default function Header() {
  return (
    <header id="header">
      <div>
        <Link to="/" title="Home">
          <Logo />
        </Link>
      </div>
    </header>
  );
}
