import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AppStatusContext } from "../Utils/AppStatus";
import "./Footer.scss";

import { ReactComponent as Logo } from "../../img/icon.svg";
var pjson = require("../../../package.json");

function Footer() {
  const { appLoading } = useContext(AppStatusContext);
  if (!appLoading) {
    return (
      <div id="Footer">
        <Logo />
        <h4 className="tSubtitle about">About us</h4>
        <p>
          Horror.ar is a community where horror fans can discover, collect, and
          review their favorite films and shows - from beloved classics to fresh
          new releases.{" "}
          <span>
            We're still getting the hang of things.{" "}
            <a href="mailto:hello@horror.ar">Got feedback</a>?
          </span>
        </p>
        <p className="small">
          Powered by our community, open source projects, and cool services like{" "}
          <a href="https://vercel.com/" target="_blank" rel="noreferrer">
            Vercel
          </a>
          ,{" "}
          <a href="https://auth0.com/" target="_blank" rel="noreferrer">
            Auth0
          </a>
          ,{" "}
          <a href="https://www.algolia.com/" target="_blank" rel="noreferrer">
            Algolia
          </a>{" "}
          and{" "}
          <a href="https://imagekit.io/" target="_blank" rel="noreferrer">
            ImageKit
          </a>
          . We get most item details from{" "}
          <a
            href="https://www.themoviedb.org/"
            target="_blank"
            rel="noreferrer"
          >
            The Movie Database.
          </a>{" "}
        </p>
        <p className="legal">
          <NavLink to="/docs/terms">Terms & conditions</NavLink>
          <NavLink to="/docs/privacy">Privacy policy</NavLink>
          <span>Version {pjson.version}</span>
        </p>
      </div>
    );
  }
}

export default Footer;
