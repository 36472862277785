import { useState, useEffect, useRef, useContext } from "react";

import AppContext from "../Utils/AppContext";
import ScoreGenres from "./ScoreGenres";

import { MoreHorizontal } from "react-feather";

import "./ItemGenres.scss";

export default function ItemGenres(props) {
  const { genres, genresLoading } = useContext(AppContext);

  /**
   * Array of genres related to this item
   */

  const genresVoted = {};

  if (props.item.genres.length > 0) {
    props.item.genres.reduce((acc, cur) => {
      const genreId = cur.genre;
      const total = cur.total;

      acc[genreId] = {
        score: total,
        rated: null,
      };

      if (props.userData.genres) {
        const userGenre = props.userData.genres.find(
          (g) => g.genre === genreId
        );

        if (userGenre) {
          const rating = userGenre.score;
          acc[genreId].rated = rating;
        }
      }

      return acc;
    }, genresVoted);
  }

  // Create a state for votedGenres and otherGenres
  const [votedGenres, setVotedGenres] = useState({});
  const [otherGenres, setOtherGenres] = useState({});

  // Create a copy of the original genres object
  const originalGenres = [...genres];

  // Create the votedGenres and otherGenres objects
  const updateGenres = () => {
    const voted = {};
    const other = {};

    // Loop through the genresVoted object
    for (const [genreId, data] of Object.entries(genresVoted)) {
      // Find the genre with the matching id in the originalGenres array
      const genre = originalGenres.find((g) => g.id === genreId);

      // If the genre is found, add it to the votedGenres object with its score
      if (genre) {
        voted[genreId] = {
          ...genre,
          score: data.score ?? 0,
          // rated: data.rated ?? null,
        };
      }
    }

    // Loop through the originalGenres array
    for (const genre of originalGenres) {
      // If the genre is not in the votedGenres object, add it to the otherGenres object with a score of 0
      if (!voted[genre.id]) {
        other[genre.id] = {
          ...genre,
          score: 0,
          // rated: null,
        };
      }
    }

    setVotedGenres(Object.values(voted).sort((a, b) => b.score - a.score));
    //setOtherGenres(other);
    setOtherGenres(
      Object.values(other).sort((a, b) =>
        a.title.toLowerCase().localeCompare(b.title.toLowerCase())
      )
    );
  };

  useEffect(() => {
    updateGenres();
  }, []);

  const genresRef = useRef(null);
  const [genresHeight, setGenresHeight] = useState(0);
  const [genresStatus, setGenresStatus] = useState("closed");

  // Genres height
  useEffect(() => {
    if (genresRef.current)
      setGenresHeight(genresRef.current.getBoundingClientRect().height);
  }, [genresRef, genresLoading, otherGenres, props.Userdata]);

  return (
    <div className="ItemGenres">
      {!genresLoading && genres && (
        <>
          {votedGenres && votedGenres[0] && (
            <ul>
              {Object.values(votedGenres).map((genre, i) => (
                <ScoreGenres
                  key={i}
                  score={genre.score}
                  genre={genre}
                  item={props.item}
                  handleItemData={props.handleItemData}
                  userData={props.userData}
                  handleUserData={props.handleUserData}
                />
              ))}
            </ul>
          )}
          {otherGenres && (
            <div
              className={
                "otherGenres " + genresStatus + (votedGenres[0] ? " voted" : "")
              }
              style={{
                "--genres-height": genresHeight + "px",
                "--voted-genres": votedGenres.length ?? 0,
              }}
            >
              <ul ref={genresRef}>
                {Object.values(otherGenres).map((genre, i) => (
                  <ScoreGenres
                    key={i}
                    genre={genre}
                    item={props.item}
                    handleItemData={props.handleItemData}
                    userData={props.userData}
                    handleUserData={props.handleUserData}
                  />
                ))}
              </ul>
              {genresHeight > 144 && (
                <button
                  className="icon showMore"
                  onClick={() => {
                    setGenresStatus("open");
                  }}
                >
                  <MoreHorizontal />
                  <small className="tSmall">
                    {Object.keys(otherGenres).length -
                      (votedGenres[0]
                        ? 10 - Object.keys(votedGenres).length
                        : 10)}{" "}
                    more
                  </small>
                </button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
