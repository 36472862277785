import { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import {
  User,
  CheckCircle,
  Eye,
  Bookmark,
  Mail,
  Layers,
  LogOut,
  Compass,
  Search,
  Tag,
  MessageCircle,
  TrendingUp,
  Zap,
} from "react-feather";

import UserContext from "../components/Auth/UserContext";
import Footer from "../components/Layout/Footer";
import ImgCdn from "../components/Utils/ImgCdn";

import "./Menu.scss";

const Menu = () => {
  const {
    isAuthenticated,
    isLoading,
    user,
    loginWithRedirect,
    logout,
    userProfile,
  } = useContext(UserContext);

  // Set avatar depending on user profile
  const [profile, setProfile] = useState("");
  const [userIcon, setUserIcon] = useState("");

  useEffect(() => {
    !isLoading && isAuthenticated && userProfile
      ? setUserIcon(
          <ImgCdn
            src={userProfile.profile.picture}
            alt={userProfile.profile.username}
            t="person_sm"
            loading="lazy"
            className="avatar"
          />
        )
      : setUserIcon(<User />);
    // Update profile on change
    setProfile(userProfile);
  }, [userProfile, isAuthenticated, isLoading]);

  // Hide menu
  const toggleMenu = () => {
    document.body.classList.remove("menuOpen");
  };

  // Window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleWindowResize = () => setWindowWidth(window.innerWidth);
  window.addEventListener("resize", handleWindowResize);

  return (
    <section id="Menu">
      <div className="wrap">
        {!isLoading && isAuthenticated ? (
          <div className="user">
            <NavLink to={"/user/" + user.username} className="userIcon" end>
              {userIcon}
            </NavLink>
            <h4 className="tSubtitle">Hello, {user.username}</h4>
            <ul>
              <li>
                <NavLink to={"/user/" + user.username} end>
                  <User />
                  Your profile
                </NavLink>
              </li>
              <li>
                <NavLink to={"/user/" + user.username + "/recommended"}>
                  <CheckCircle />
                  Recommendations
                </NavLink>
              </li>
              <li>
                <NavLink to={"/user/" + user.username + "/watchlist"}>
                  <Bookmark />
                  Watchlist{" "}
                  {profile?.profile?.stats?.watchlist > 0 && (
                    <span className="pill">
                      {profile.profile.stats.watchlist}
                    </span>
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink to={"/user/" + user.username + "/watched"}>
                  <Eye />
                  Watched
                  {profile?.profile?.stats?.watched > 0 && (
                    <span className="pill">
                      {profile.profile.stats.watched}
                    </span>
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink to={"/user/" + user.username + "/lists"}>
                  <Layers />
                  Your lists
                  {profile?.profile?.stats?.lists > 0 && (
                    <span className="pill">{profile.profile.stats.lists}</span>
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink to={"/user/" + user.username + "/reviews"}>
                  <MessageCircle />
                  Your reviews
                  {profile?.profile?.stats?.reviews > 0 && (
                    <span className="pill">
                      {profile.profile.stats.reviews}
                    </span>
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/quickrate"
                  className={
                    profile?.profile?.stats?.watched < 10 ? "hot" : null
                  }
                >
                  <Zap />
                  Quick rate
                </NavLink>
              </li>
              <li>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    });
                    localStorage.removeItem("userProfile");
                  }}
                >
                  <LogOut />
                  Log Out
                </a>
              </li>
            </ul>
          </div>
        ) : (
          <div className="buttons">
            <button onClick={() => loginWithRedirect()}>Log in</button>
            <button
              className="subtle"
              onClick={() =>
                loginWithRedirect({
                  authorizationParams: {
                    screen_hint: "signup",
                  },
                })
              }
            >
              Sign up
            </button>
          </div>
        )}
        <h4 className="tSubtitle">Sections</h4>
        <ul>
          <li>
            <NavLink to="/">
              <Compass />
              Discover
            </NavLink>
          </li>
          <li>
            <NavLink to="/items/popular">
              <TrendingUp />
              Popular
            </NavLink>
          </li>
          <li>
            <NavLink to="/search">
              <Search />
              Search
            </NavLink>
          </li>
          <li>
            <NavLink to="/genres">
              <Tag />
              Genres
            </NavLink>
          </li>
          <li>
            <NavLink to="/lists">
              <Layers />
              Lists
            </NavLink>
          </li>
          <li>
            <NavLink to="/items/reviews">
              <MessageCircle />
              Reviews
            </NavLink>
          </li>
          <li>
            <a href="mailto:hello@horror.ar">
              <Mail />
              Contact us
            </a>
          </li>
        </ul>
        {windowWidth < 840 && <Footer />}
      </div>
      <div className="trap" onClick={toggleMenu}></div>
    </section>
  );
};

export default Menu;
