import { useState, useEffect, createContext } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CloudOff } from "react-feather";

const AppStatusContext = createContext();

const AppStatus = ({ children }) => {
  // Current location
  const location = useLocation();

  // Is loading?
  const [appLoading, setAppLoading] = useState(true);
  // console.log(appLoading, "appLoading");

  useEffect(() => {
    setAppLoading(true); // Set loading to true on every path change
  }, [location]);

  // Is online?
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  // Transitions
  const currentView = location.pathname.replace(/\//g, "").trim() || "home";
  const [displayLocation, setDisplayLocation] = useState(location);

  useEffect(() => {
    if (location !== displayLocation) {
      setDisplayLocation(location);
      window.scrollTo(0, 0);
    }
    document.body.className = currentView ? currentView : "home";
  }, [location, displayLocation, currentView]);

  // Main class
  const classNames = [
    currentView,
    isOnline ? "online" : "offline",
    appLoading ? "loading" : "done",
  ].join(" ");

  const [userCountry, setUserCountry] = useState("");
  const [userCountryName, setUserCountryName] = useState("");

  useEffect(() => {
    async function fetchUserCountry() {
      try {
        const response = await fetch("https://ipapi.co/json/", {
          cache: "force-cache",
        });
        const data = await response.json();
        setUserCountry(data.country);
        setUserCountryName(data.country_name ?? null);
      } catch (error) {
        console.error("Error fetching user country:", error);
      }
    }
    fetchUserCountry();
  }, []);

  return (
    <AppStatusContext.Provider
      value={{
        appLoading,
        setAppLoading,
        classNames,
        userCountry,
        userCountryName,
      }}
    >
      {children}
      <ToastContainer position="bottom-center" theme="dark" />
      <div id="offline" className={isOnline ? "hidden" : "active"}>
        <CloudOff />
        <p className="tSmall">You're offline</p>
      </div>
    </AppStatusContext.Provider>
  );
};

export { AppStatusContext, AppStatus };
