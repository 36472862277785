import { useState, useEffect } from "react";
import CountUp from "react-countup";
import "./Score.scss";

export default function ScoreStars(props) {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount < props.score) {
          return prevCount + 1;
        } else {
          clearInterval(interval); // Stop the interval when count reaches props.score
          return prevCount;
        }
      });
    }, 1000 / props.score); // Calculate the interval time based on the desired duration of 2 seconds

    return () => clearInterval(interval);
  }, [props.score]);

  const scoreClass =
    props.score < 40 ? "negative" : props.score < 60 ? "neutral" : "positive";

  if (props.score) {
    return (
      <div className={"Score " + scoreClass}>
        <div
          className="bar"
          style={{
            background: `conic-gradient(var(--color) ${count}%, transparent 0 100%)`,
          }}
        >
          {props.score}
        </div>
        <CountUp end={props.score ?? 0} duration={1.5} suffix="%" />
      </div>
    );
  }
}
