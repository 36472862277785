import { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { AppStatusContext } from "../components/Utils/AppStatus";

import "../styles/__Fullscreen.scss";

const imgs = [
  "blairwitch",
  "conjuring",
  "freddy",
  "hellraiser",
  "nosferatu",
  "pennywise",
  "mike",
  "jason",
  "smile",
  "us",
  "it-follows",
  "exorcist",
];

const Error = () => {
  const { appLoading, setAppLoading } = useContext(AppStatusContext);

  const [rand, setRand] = useState(0);
  useEffect(() => {
    const i = Math.floor(Math.random() * imgs.length);
    setRand(i);
    setAppLoading(false);
  }, [appLoading, setAppLoading]);

  return (
    <section id="Error" className="Fullscreen">
      <div className="text">
        <h1>
          Sorry, this content was not found.{" "}
          <span>
            But it seems that something <strong>has found you...</strong>
          </span>
        </h1>
        <p>
          Better{" "}
          <NavLink to="/" className="underline">
            run back home
          </NavLink>
          !
        </p>
      </div>
      <img src={"/img/error/" + imgs[rand] + ".gif"} alt="" />
    </section>
  );
};

export default Error;
