import { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ChevronRight } from "react-feather";

import AppContext from "../components/Utils/AppContext";
import { AppStatusContext } from "../components/Utils/AppStatus";
import UserContext from "../components/Auth/UserContext";
import HeroIntro from "../components/Hero/Intro";
import Posters from "../components/Items/Posters";
import Reviews from "../components/Items/PreviewReviews";
import Collections from "../components/Items/Collections";
import Teaser from "../components/Blocks/Teaser";
import Quote from "../components/Blocks/Quote";
import People from "../components/Items/People";

import "./Home.scss";

function Home() {
  // Context
  const { genres, genresLoading } = useContext(AppContext);
  const { appLoading, setAppLoading } = useContext(AppStatusContext);

  // Random genres
  const randomGenres =
    !genresLoading && genres[0]
      ? [...genres].sort(() => Math.random() - 0.5).slice(0, 15)
      : null;

  const {
    user,
    isAuthenticated,
    isVerified,
    isLoading,
    userProfile,
    loginWithRedirect,
  } = useContext(UserContext);

  // Define items
  const [items, setItems] = useState([{}]);

  // Today's date
  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
  });

  // Api URLs
  const urlBase =
    process.env.REACT_APP_API_URL +
    "/items/?token=" +
    process.env.REACT_APP_API_KEY;

  const api = {
    popular: urlBase + "&order_sort=ASC&order_type=popularity&max=15",
    upcoming: urlBase + "&order_type=upcoming&seasons=true&max=15",
    recent: urlBase + "&order_type=recent&seasons=true&max=15",
    polarizing: urlBase + "&order_type=polarizing&max=15",
    watched: urlBase + "&order_type=watched",
    watchlist: urlBase + "&order_type=watchlist",
    today:
      process.env.REACT_APP_API_URL +
      "/today/?token=" +
      process.env.REACT_APP_API_KEY +
      "&max=10",
    reviews:
      process.env.REACT_APP_API_URL +
      "/reviews/?token=" +
      process.env.REACT_APP_API_KEY +
      "&max=5",
    lists:
      process.env.REACT_APP_API_URL +
      "/lists/?token=" +
      process.env.REACT_APP_API_KEY +
      "&max=5",
    bornToday:
      process.env.REACT_APP_API_URL +
      "/birthday/?token=" +
      process.env.REACT_APP_API_KEY,
  };

  /**
   * Load items
   */
  useEffect(() => {
    const cachedHome = localStorage.getItem("home");
    const expirationTime = localStorage.getItem("home_expiration_time");
    const currentTime = new Date().getTime();

    if (cachedHome && expirationTime && currentTime < expirationTime) {
      setItems(JSON.parse(cachedHome));
    } else {
      Promise.all([
        fetch(api.popular),
        fetch(api.upcoming),
        fetch(api.recent),
        fetch(api.polarizing),
        fetch(api.today),
        fetch(api.reviews),
        fetch(api.lists),
        fetch(api.watched),
        fetch(api.watchlist),
        fetch(api.bornToday),
      ])
        .then(function (responses) {
          return Promise.all(
            responses.map(function (response) {
              return response.json();
            })
          );
        })
        .then(function (data) {
          const result = {
            popular: data[0].status !== "error" ? data[0].results : {},
            upcoming: data[1].status !== "error" ? data[1].results : {},
            recent: data[2].status !== "error" ? data[2].results : {},
            polarizing: data[3].status !== "error" ? data[3].results : {},
            today: data[4].status !== "error" ? data[4].result : {},
            reviews: data[5].status !== "error" ? data[5].reviews : {},
            lists: data[6].status !== "error" ? data[6].lists : {},
            watched: data[7].status !== "error" ? data[7].results : {},
            watchlist: data[8].status !== "error" ? data[8].results : {},
            bornToday: data[9].status !== "error" ? data[9].result.people : {},
            shuffled:
              data[0].status !== "error" &&
              data[1].status !== "error" &&
              data[2].status !== "error"
                ? [...data[0].results, ...data[1].results, ...data[2].results]
                    .sort(() => Math.random() - 0.5)
                    .slice(0, 30)
                : {},
          };

          localStorage.setItem("home", JSON.stringify(result));

          // Set expiration time to 4 hours from now
          const expirationTime = new Date().getTime() + 4 * 60 * 60 * 1000;
          localStorage.setItem("home_expiration_time", expirationTime);
          setItems(result);
          setAppLoading(false);
        })
        .catch(function (error) {
          setItems({});
          setAppLoading(false);
        });
    }
  }, []);

  /**
   * Load recommendations
   */
  const [loadingRecs, setLoadingRecs] = useState(true);
  const [recommendations, setRecommendations] = useState(false);
  useEffect(() => {
    setAppLoading(false);
    if (!isLoading) {
      if (
        isAuthenticated &&
        isVerified &&
        userProfile?.recent?.recommendations?.length > 0
      ) {
        setRecommendations(userProfile.recent.recommendations);
        setLoadingRecs(false);
      } else {
        setRecommendations(false);
        setLoadingRecs(false);
      }
    }
  }, [isLoading, appLoading, isAuthenticated, userProfile, setAppLoading]);

  return (
    <section id="Home">
      <HeroIntro items={items.shuffled ?? null} />

      <h2 className="cSubtitle">
        {!isLoading && !appLoading && isAuthenticated && recommendations ? (
          <NavLink to={"/user/" + user.username + "/recommended"}>
            Recommended for {user.username}
            <ChevronRight />
          </NavLink>
        ) : (
          <>Recommended for you</>
        )}
      </h2>

      <div className="recommendations">
        {!isLoading && !loadingRecs && !recommendations && (
          <Teaser
            isAuthenticated={isAuthenticated}
            isVerified={isVerified}
            loginWithRedirect={loginWithRedirect}
          />
        )}

        <Posters
          horizontal={true}
          scroll={!loadingRecs && !recommendations ? false : true}
          items={recommendations}
          login={true}
          more={{
            path: recommendations
              ? "/user/" + user.username + "/recommended"
              : "/profile",
            text: "More",
          }}
        />
      </div>

      {items.today && items.today.items && (
        <>
          <h2 className="cSubtitle">
            <NavLink to="/items/today">
              <em>Season's Scares: </em> {items.today.title}
              <ChevronRight />
            </NavLink>
          </h2>
          <Posters
            items={items.today.items}
            horizontal={true}
            more={{ path: "/items/today" }}
          />
        </>
      )}

      <h2 className="cSubtitle">
        <NavLink to="/items/popular">
          Popular Horror Films & Shows
          <ChevronRight />
        </NavLink>
      </h2>
      <Posters
        items={items.popular}
        horizontal={true}
        ranking={true}
        more={{ path: "/items/popular" }}
      />

      <Quote />

      <h2 className="cSubtitle">
        <NavLink to="/items/coming-soon">
          Coming Soon
          <ChevronRight />
        </NavLink>
      </h2>
      <Posters
        items={items.upcoming}
        horizontal={true}
        more={{ path: "/items/coming-soon" }}
      />

      <h2 className="cSubtitle">
        <NavLink to="/items/recent">
          Fresh frights
          <ChevronRight />
        </NavLink>
      </h2>
      <Posters
        items={items.recent}
        horizontal={true}
        more={{ path: "/items/recent" }}
      />

      <h2 className="cSubtitle">Love It or Dread It</h2>
      <Posters items={items.polarizing} horizontal={true} />

      <h2 className="cSubtitle">
        <NavLink to="/items/reviews">
          Popular reviews
          <ChevronRight />
        </NavLink>
      </h2>
      <Reviews reviews={items.reviews} />

      <h2 className="cSubtitle">
        <NavLink to="/lists">
          Popular lists
          <ChevronRight />
        </NavLink>
      </h2>
      <Collections items={items.lists} />

      {!genresLoading && (
        <>
          <h2 className="cSubtitle">
            <NavLink to="/genres">
              Genres
              <ChevronRight />
            </NavLink>
          </h2>
          <Posters
            items={randomGenres}
            horizontal={true}
            more={{ path: "/genres" }}
          />
        </>
      )}

      <h2 className="cSubtitle">
        <NavLink to="/items/watched">
          Most watched this month
          <ChevronRight />
        </NavLink>
      </h2>
      <Posters
        items={items.watched}
        horizontal={true}
        more={{ path: "/items/watched" }}
      />

      <h2 className="cSubtitle">
        <NavLink to="/items/watchlist">
          Most watchlisted this month
          <ChevronRight />
        </NavLink>
      </h2>
      <Posters
        items={items.watchlist}
        horizontal={true}
        more={{ path: "/items/watchlist" }}
      />

      {items.bornToday && items.bornToday.length > 0 && (
        <>
          <h2 className="cSubtitle">Born today, {formattedDate}</h2>
          <People items={items.bornToday} birthday={true} />
        </>
      )}
    </section>
  );
}
export default Home;
