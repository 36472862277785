import { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { ArrowDown, Search, Grid, MoreHorizontal } from "react-feather";

import UserContext from "../Auth/UserContext";
import Poster from "./Poster";

import "./Posters.scss";
import "../../styles/__Filters.scss";

// Empty posters
const EmptyPosters = ({ n, login = false }) => {
  const { isLoading } = useContext(UserContext);
  let emptyPosters = [];
  for (let i = 0; i < n; ++i) {
    emptyPosters.push(
      <li key={i} className="placeholder">
        <div className="item">
          <figure
            className={login && !isLoading ? "" : "imgPlaceholder"}
          ></figure>
          <p>
            <strong>Title</strong>
            <span className="tSmall">Type &middot; date</span>
          </p>
        </div>
      </li>
    );
  }

  return <>{emptyPosters}</>;
};

export default function Posters(props) {
  const { isAuthenticated, isVerified, isLoading } = useContext(UserContext);

  const [items, setItems] = useState({});
  const [sortOrder, setSortOrder] = useState(props.sortOrder ?? "desc");
  const [sortOrderField, setSortOrderField] = useState("date");
  const [sortOptions, setSortOptions] = useState([
    { label: "Release", value: "date" },
  ]);

  const layouts = ["Default", "Rows", "List"];
  const [layout, setLayout] = useState("Default");
  const [ordering, setOrdering] = useState("");

  // Get content
  useEffect(() => {
    // Define items
    if (
      props.items &&
      props.login &&
      !isLoading &&
      (!isAuthenticated || !isVerified)
    ) {
      setItems({});
    } else {
      setItems(props.items);

      // Define sort options
      if (props.sortable) {
        const hasDateAdded = Object.keys(props.items).some(
          (key) => "date_added" in props.items[key]
        );
        const hasPosition = Object.keys(props.items).some(
          (key) => "position" in props.items[key]
        );
        const hasScore = Object.keys(props.items).some(
          (key) => "score" in props.items[key]
        );

        const options = [
          { label: "Title", value: "title" },
          { label: "Release", value: "date" },
          ...(hasDateAdded ? [{ label: "Added", value: "date_added" }] : []),
          ...(hasPosition ? [{ label: "Position", value: "position" }] : []),
          ...(hasScore ? [{ label: "Score", value: "score" }] : []),
        ];

        setSortOptions(options);
      }
    }
  }, [props, isLoading, isAuthenticated, isVerified]);

  // Get layout and order from local storage
  useEffect(() => {
    if (sortOptions && props.sortable) {
      // Layout
      const localLayout = window.localStorage.getItem("boo_layout");
      if (localLayout !== null) setLayout(JSON.parse(localLayout));

      const hasPosition = Object.keys(props.items).some(
        (key) => "position" in props.items[key]
      );
      if (hasPosition) {
        setSortOrderField("position");
        setSortOrder("asc");
      }

      // Order
      /*
      const localOrder = window.localStorage.getItem("boo_order");
      const localOrderField = window.localStorage.getItem("boo_order_field");
      
      const hasPosition = Object.keys(props.items).some(
        (key) => "position" in props.items[key]
      );

      if (hasPosition) {
        setSortOrderField("position");
        setSortOrder("asc");
      } else if (
        sortOptions &&
        localOrder !== null &&
        localOrderField !== null
      ) {
        setSortOrder(JSON.parse(localOrder));

        setSortOrderField(
          sortOptions.some(
            (option) => option.value === JSON.parse(localOrderField)
          )
            ? JSON.parse(localOrderField)
            : "date"
        );
      }
      */
    }
  }, [props, sortOptions]);

  /**
   * Reorder items
   */
  useEffect(() => {
    if (items && Object.keys(items).length > 0) {
      if (props.sortable) {
        setOrdering("ordering");

        const sortableItems =
          sortOrderField === "score"
            ? items.filter((item) => item["score"] !== null)
            : items;

        setTimeout(function () {
          // Reorder items
          const orderedItems = sortableItems.sort((a, b) => {
            const fieldA =
              sortOrderField === "date" && a.date === null
                ? a.year
                : a[sortOrderField];
            const fieldB =
              sortOrderField === "date" && b.date === null
                ? b.year
                : b[sortOrderField];

            // Handle null values or empty strings for fieldA and fieldB
            if (fieldA === null || fieldA === "")
              return sortOrder === "asc" ? 1 : -1;
            if (fieldB === null || fieldB === "")
              return sortOrder === "asc" ? -1 : 1;

            // Check if the sortOrderField is 'score' and handle items without a score
            if (sortOrderField === "score") {
              if (isNaN(fieldA) || isNaN(fieldB)) {
                // Filter items without score only when sorting by 'score'
                return fieldA !== fieldB ? fieldA - fieldB : 0;
              }
            }

            // For other fields or when sorting by a non-'score' field, proceed with regular sorting
            if (!isNaN(fieldA) && !isNaN(fieldB)) {
              return sortOrder === "asc" ? fieldA - fieldB : fieldB - fieldA;
            } else if (sortOrderField === "date") {
              // Handling dates
              if (isNaN(fieldA) && isNaN(fieldB)) {
                if (!fieldA && !fieldB) return 0; // If both are empty, consider them equal
                return sortOrder === "asc"
                  ? fieldA.localeCompare(fieldB)
                  : fieldB.localeCompare(fieldA);
              }
            }

            return sortOrder === "asc"
              ? fieldA.localeCompare(fieldB)
              : fieldB.localeCompare(fieldA);
          });

          setFilteredItems(orderedItems);

          // Save params to local storage
          /*
          window.localStorage.setItem("boo_order", JSON.stringify(sortOrder));
          window.localStorage.setItem(
            "boo_order_field",
            JSON.stringify(sortOrderField)
          );
          */

          setOrdering();
        }, 300);
      } else {
        setFilteredItems(items);
      }
    }
  }, [sortOrder, sortOrderField, props, items]);

  // Search
  const [searching, setSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState({});

  function handleSearching() {
    setSearching(!searching);
    if (searching) setSearchTerm("");
  }

  useEffect(() => {
    items &&
      items.length &&
      (searchTerm
        ? setFilteredItems(
            items.filter((item) =>
              item.title.toLowerCase().includes(searchTerm.toLowerCase())
            )
          )
        : setFilteredItems(items));
  }, [searchTerm]);

  // Change layout style
  const handleLayout = () => {
    // const newLayout = layout === "default" ? "rows" : "default";
    setOrdering("ordering");
    setTimeout(function () {
      // Get the index of the current layout in the layouts array
      const currentIndex = layouts.indexOf(layout);

      // Calculate the index of the next layout, wrapping around to the start of the array if necessary
      const nextIndex = (currentIndex + 1) % layouts.length;

      // Get the next layout from the layouts array
      const newLayout = layouts[nextIndex];

      setLayout(newLayout);
      window.localStorage.setItem("boo_layout", JSON.stringify(newLayout));
      // Show

      setOrdering();
    }, 300);
  };

  // Classes
  const classes = ["Posters"];
  classes.push(props.horizontal ? "HorizontalScroll" : "Grid");
  if (layout) classes.push(layout);
  if (props.scroll === false || !items || items.length === 0)
    classes.push("empty");

  return (
    <>
      {props.sortable && props.items.length > 1 && filteredItems && (
        <div
          className={`Filters ${searching ? "searching" : ""}`}
          data-intab={props.inTab}
          data-inuser={props.inUser}
          data-noparent={props.noParent}
        >
          {filteredItems.length && (
            <span className="label tSmall">{filteredItems.length} items</span>
          )}
          <input
            type="search"
            value={searchTerm}
            placeholder="Filter items"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button className="icon search" onClick={handleSearching}>
            <Search />
          </button>
          <button className={"icon layout " + layout} onClick={handleLayout}>
            <Grid />
          </button>
          <select
            className="field"
            value={sortOrderField}
            onChange={(e) => {
              const selectedField = e.target.value;
              setSortOrderField(selectedField);
            }}
          >
            {sortOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <button
            className={"icon  sort " + sortOrder}
            onClick={() => {
              setSortOrder(sortOrder === "desc" ? "asc" : "desc");
            }}
          >
            <ArrowDown />
          </button>
        </div>
      )}
      <div className={classes.join(" ")}>
        {items && items.length && filteredItems && filteredItems.length ? (
          <ul className={ordering}>
            {filteredItems.map((item, index) => (
              <Poster
                key={index}
                ranking={props.ranking}
                index={index + 1}
                total={items.length}
                {...item}
              />
            ))}
            {props.more && (
              <li className="more">
                <NavLink to={props.more.path}>
                  <figure className={items[0].type}>
                    <p className="tSubtitle">
                      {props.more.text ?? "More"} <MoreHorizontal />
                    </p>
                  </figure>
                </NavLink>
              </li>
            )}
          </ul>
        ) : searchTerm.length ? (
          <></>
        ) : (
          <>
            <ul>
              <EmptyPosters n="10" login={props.login} />
            </ul>
          </>
        )}
      </div>
    </>
  );
}
