import { useRef, useState, useEffect, useContext } from "react";
import { Plus, Trash2 } from "react-feather";

import UserContext from "../Auth/UserContext";
import ImgCdn from "../Utils/ImgCdn";
import Loader from "../Utils/Loader";
import avatar from "../../img/person_lg.png";

import * as dayjs from "dayjs";
import * as localizedFormat from "dayjs/plugin/localizedFormat";

import "./ReviewItem.scss";

dayjs.extend(localizedFormat);

const maxChars = 666;

export default function ReviewItem(props) {
  const { userProfile, isAuthenticated, getAccessTokenSilently } =
    useContext(UserContext);

  const [review, setReview] = useState(false);
  const [reviewing, setReviewing] = useState(false);
  const [characters, setCharacters] = useState(maxChars);

  const textareaRef = useRef(null);
  const [currentValue, setCurrentValue] = useState("");

  // Reviewed?
  useEffect(() => {
    if (props.userData && props.userData.review?.review) {
      setReview(props.userData.review);
    }
  }, [props]);

  /**
   * Resize textarea and handle limits
   */
  const handleInputChange = (event) => {
    const textarea = event.target;
    textarea.style.height = "auto";
    const lineHeight = parseInt(getComputedStyle(textarea).lineHeight);
    const numberOfLines = Math.max(
      1,
      Math.ceil(textarea.scrollHeight / lineHeight)
    );
    textarea.style.height = `${numberOfLines * lineHeight}px`;
    setCurrentValue(textarea.value.substring(0, maxChars));
    setCharacters(maxChars - textarea.value.length);
  };

  /**
   * Process review
   */

  const handleReview = async (e) => {
    e.preventDefault();
    setReviewing(true);

    if (isAuthenticated) {
      const access_token = await getAccessTokenSilently({
        ignoreCache: true,
      });

      const formData = new URLSearchParams();
      formData.append("access_token", access_token);
      formData.append("item", props.item.id);
      formData.append("action", "add");
      formData.append("review", currentValue);
      fetch(
        process.env.REACT_APP_API_URL +
          "/user/actions/?action=reviewItem&token=" +
          process.env.REACT_APP_API_KEY,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.status !== "error") {
            // Push
            setCurrentValue("");
            setReview({ review: currentValue });

            // Update user data
            props.handleUserData("review", { review: currentValue });
          }
          setReviewing(false);
        });
    }
  };

  /**
   * Delete review
   */

  const deleteReview = async (e) => {
    e.preventDefault();
    setReviewing(true);

    const confirmed = window.confirm(
      "Are you sure you want to delete this review?"
    );

    if (confirmed && isAuthenticated) {
      const access_token = await getAccessTokenSilently({
        ignoreCache: true,
      });

      const formData = new URLSearchParams();
      formData.append("access_token", access_token);
      formData.append("item", props.item.id);
      formData.append("action", "delete");

      fetch(
        process.env.REACT_APP_API_URL +
          "/user/actions/?action=reviewItem&token=" +
          process.env.REACT_APP_API_KEY,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.status !== "error") {
            // Push
            setReview(false);

            // Update user data
            props.handleUserData("review", {});
          }
          setReviewing(false);
        });
    } else {
      setReviewing(false);
    }
  };

  return (
    <div className="ReviewItem" data-reviewing={reviewing ? "true" : "false"}>
      {review ? (
        <div className="userContent review">
          <h2 className="tSubtitle">Your review:</h2>
          {review.review
            .split(/\n|\n\n/)
            .map((line, index) =>
              line === "" ? <br key={index} /> : <p key={index}>{line}</p>
            )}
        </div>
      ) : (
        <>
          <form className="userContent" onSubmit={handleReview}>
            <textarea
              name="review"
              id="review"
              ref={textareaRef}
              placeholder={
                "What did you think about this " + props.item.type + "?"
              }
              onChange={handleInputChange}
              onInput={handleInputChange}
              value={currentValue}
            />
            <p className="action">
              <button
                disabled={
                  currentValue.length >= maxChars || currentValue.length <= 15
                }
              >
                Add your review
                {reviewing ? <Loader /> : <Plus />}
              </button>
            </p>
            <span className="characters">{characters}</span>
          </form>
        </>
      )}
      <div className="user">
        <figure className="avatar">
          {userProfile.profile.picture ? (
            <ImgCdn
              src={userProfile.profile.picture}
              alt={userProfile.profile.username}
              t="person_sm"
              loading="lazy"
              className="avatar"
            />
          ) : (
            <img src={avatar} alt="" />
          )}
        </figure>
        {review && (
          <>
            <p>
              <small className="tSmall">
                Reviewed {dayjs(review.timestamp).format("LL")}
              </small>
            </p>
            <button className="round icon small" onClick={deleteReview}>
              {reviewing ? <Loader /> : <Trash2 />}
            </button>
          </>
        )}
      </div>
    </div>
  );
}
