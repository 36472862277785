import { useContext, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { AppStatusContext } from "../components/Utils/AppStatus";

import HeroItem from "../components/Hero/Item";
import Posters from "../components/Items/Posters";

const Genre = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const { appLoading, setAppLoading } = useContext(AppStatusContext);

  const [genre, setGenre] = useState({});

  const url =
    process.env.REACT_APP_API_URL +
    "/genre/?token=" +
    process.env.REACT_APP_API_KEY +
    "&id=" +
    id;

  // Load genre
  useEffect(() => {
    fetch(url, { cache: "default" })
      .then((response) => response.json())
      .then((data) => {
        setGenre(data.genre);
        setAppLoading(false);
      });
  }, [url]);

  // Reset on path change
  useEffect(() => {
    setGenre({});
  }, [pathname]);

  return (
    <section id="Genre">
      {!appLoading && Object.keys(genre).length !== 0 && (
        <Helmet>
          <title>Genre: {genre.title} &middot; Horror.ar</title>
          {genre.description && (
            <meta name="description" content={genre.description} />
          )}
          {genre.cover && (
            <meta
              property="og:image"
              content={`https://ik.imagekit.io/${process.env.REACT_APP_CDN_ID}
            /tr:n-cover_lg/${genre.cover}`}
            />
          )}
        </Helmet>
      )}
      <HeroItem
        item={genre}
        display="genre"
        isLoading={appLoading}
        type="small"
      />

      <Posters items={genre.items ?? {}} sortable={true} noParent={true} />
    </section>
  );
};

export default Genre;
