import { useState, useEffect, useContext } from "react";
import UserContext from "../Auth/UserContext";
import { NavLink, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import Loader from "../Utils/Loader";

import { ThumbsUp } from "react-feather";

import "./ScoreSomething.scss";

export default function ScoreGenres(props) {
  const location = useLocation();
  const { loginWithRedirect, isAuthenticated, getAccessTokenSilently } =
    useContext(UserContext);
  const [voting, setVoting] = useState(false);

  const [currentScore, setCurrentScore] = useState(props.score ?? 0);
  const [userVoted, setUserVoted] = useState(null);

  // Voted?
  useEffect(() => {
    if (props.userData.genres) {
      setUserVoted(
        props.userData.genres.findIndex(
          (item) => Number(item.genre) === Number(props.genre.id)
        ) >= 0
          ? true
          : false
      );
    }
  }, [props]);

  const handleRating = async (index) => {
    setVoting(true);

    if (isAuthenticated) {
      const access_token = await getAccessTokenSilently({
        ignoreCache: true,
      });

      const formData = new URLSearchParams();
      formData.append("access_token", access_token);
      formData.append("item", props.item.id);
      formData.append("genre", props.genre.id);
      formData.append("score", index);
      fetch(
        process.env.REACT_APP_API_URL +
          "/user/actions/?action=rateGenre&token=" +
          process.env.REACT_APP_API_KEY,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.status !== "error") {
            const voteDiff = index === 0 ? -1 : 1;
            const updatedScore = Number(currentScore) + Number(voteDiff);
            setCurrentScore(updatedScore);
            setUserVoted(index ? true : false);

            const updatedItemGenres = [...props.item.genres] ?? {};
            const genreKey = updatedItemGenres.findIndex(
              (item) => Number(item.genre) === Number(props.genre.id)
            );

            // Update in handler
            if (genreKey >= 0) {
              updatedItemGenres[genreKey].total = updatedScore;
            } else {
              updatedItemGenres.push({
                genre: Number(props.genre.id),
                total: 1,
              });
            }
            props.handleItemData("genres", updatedItemGenres);

            if (props.userData && props.handleUserData) {
              const updatedUserGenres = [...props.userData.genres];
              const genreKey = updatedUserGenres.findIndex(
                (item) => Number(item.genre) === Number(props.genre.id)
              );

              // Update in handler
              if (genreKey >= 0) {
                updatedUserGenres.splice(genreKey, 1);
              } else {
                updatedUserGenres.push({
                  genre: Number(props.genre.id),
                  score: 1,
                });
              }

              props.handleUserData("genres", updatedUserGenres);
            }
          } else {
            if (
              res.message.startsWith(
                "Expiration Time (exp) claim error in the token"
              )
            ) {
              toast.error("Your session has expired.");
              setTimeout(function () {
                loginWithRedirect({
                  prompt: "login",
                  appState: { target: location.pathname },
                });
              });
            }
          }
          setVoting(false);
        });
    }
  };

  return (
    <li data-status={currentScore > 0 ? "voted" : null}>
      <NavLink to={"/genre/" + props.genre.id}>{props.genre.title}</NavLink>
      <div
        className="ScoreSomething"
        data-voting={voting}
        data-enabled={isAuthenticated}
      >
        <span className="score">{voting ? <Loader /> : currentScore}</span>
        <button
          className="icon small up"
          data-rated={userVoted}
          onClick={() => handleRating(userVoted ? 0 : 1)}
        >
          <ThumbsUp />
        </button>
      </div>
    </li>
  );
}
