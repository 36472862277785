import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { Blurhash } from "react-blurhash";
import ImgCdn from "../Utils/ImgCdn";

import "./Quote.scss";

const url =
  process.env.REACT_APP_API_URL +
  "/quote/?token=" +
  process.env.REACT_APP_API_KEY;

export default function Quote() {
  const [isLoading, setIsLoading] = useState(true);
  const [quote, setQuote] = useState({});

  // Get quote
  useEffect(() => {
    fetch(url, { cache: "default" })
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== "error") {
          setQuote(data.quote);
          setIsLoading(false);
        }
      });
  }, []);

  return (
    <div
      className={"Quote" + (isLoading ? " Loading imgPlaceholder" : " Done")}
    >
      <div className="text">
        <p>{quote.text}</p>
      </div>
      {quote.item && (
        <p className="item tSmall">
          <NavLink to={"/item/" + quote.item.id}>{quote.item.title}</NavLink>
        </p>
      )}
      <figure className="blurhash">
        {quote.cover_blurhash && (
          <Blurhash hash={quote.cover_blurhash} width={720} height={405} />
        )}
        {quote.cover && <ImgCdn src={quote.cover} alt="" t="cover_lg" />}
      </figure>
    </div>
  );
}
