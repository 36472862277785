import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams, useLocation, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

import { AppStatusContext } from "../components/Utils/AppStatus";
import UserContext from "../components/Auth/UserContext";

import { ChevronRight, LogOut } from "react-feather";

import UserView from "./UserView";
import Verify from "../components/User/Verify";
import Update from "../components/User/Update";
import HeroItem from "../components/Hero/Item";
import Posters from "../components/Items/Posters";
import Reviews from "../components/Items/PreviewReviews";
import Collections from "../components/Items/Collections";

import "./Profile.scss";

const Profile = () => {
  const { pathname } = useLocation();
  const { username } = useParams();
  const { view } = useParams();

  const navigate = useNavigate();
  const { appLoading, setAppLoading } = useContext(AppStatusContext);

  const { user, isAuthenticated, isLoading, isVerified, userProfile, logout } =
    useContext(UserContext);

  // If no username...
  useEffect(() => {
    if (!username) {
      navigate("/");
    }
  }, [pathname, username, navigate]);

  // User defs
  const [recent, setRecent] = useState({});
  const [profileUser, setProfileUser] = useState(false);
  const [isSelf, setIsSelf] = useState(false);

  // Handle updates in users
  function handleUserUpdate(key, value) {
    setProfileUser((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
    localStorage.removeItem("userProfile");
  }

  // Define if the user is seeing himself
  useEffect(() => {
    if (!isLoading && user && isAuthenticated && username === user.username) {
      setIsSelf(true);
    } else {
      setIsSelf(false);
    }
  }, [isLoading, username, user, isAuthenticated]);

  /**
   * Get user recent/main items
   */
  useEffect(() => {
    if (!isLoading) {
      if (
        user &&
        isAuthenticated &&
        userProfile &&
        userProfile.recent &&
        isSelf
      ) {
        setProfileUser(userProfile.profile);
        setRecent((prevState) => ({
          ...prevState,
          reviews: userProfile.recent.reviews,
          lists: userProfile.recent.lists,
          watched: userProfile.recent.watched,
          watchlist: userProfile.recent.watchlist,
          recommendations: userProfile.recent.recommendations,
        }));
        setAppLoading(false);
      } else {
        const userUrl =
          process.env.REACT_APP_API_URL +
          "/user/profile/?token=" +
          process.env.REACT_APP_API_KEY +
          "&user=" +
          username;
        fetch(userUrl, { cache: "default" })
          .then((response) => response.json())
          .then((data) => {
            if (data.status !== "error") {
              setProfileUser(data.user.profile);
              setRecent((prevState) => ({
                ...prevState,
                reviews: data.user.recent.reviews,
                lists: data.user.recent.lists,
                watched: data.user.recent.watched,
                watchlist: data.user.recent.watchlist,
              }));
              setAppLoading(false);
            } else {
              // No activity yet!
              setProfileUser(
                isSelf ? user : { username: username, picture: "" }
              );
              setRecent({});
              setAppLoading(false);
            }
          })
          .catch(function (error) {
            //console.log(error);
          });
      }
    }
  }, [isLoading, username, isSelf, isAuthenticated, userProfile, user]);

  /**
   * Return
   */

  if (isAuthenticated && !isVerified) {
    return (
      <section id="Profile">
        <Helmet>
          <title>Verify your email &middot; Horror.ar</title>
        </Helmet>
        <Verify user={user} />
      </section>
    );
  }

  return (
    <section id="Profile">
      <HeroItem item={profileUser} isLoading={appLoading} type="small" />

      {!isLoading && isAuthenticated && user && isSelf && (
        <>
          <div className="actions over">
            <button
              className="round over"
              onClick={() => {
                logout({
                  logoutParams: { returnTo: window.location.origin },
                });
                localStorage.removeItem("userProfile");
              }}
            >
              <LogOut />
            </button>
          </div>
          <Update handleUserUpdate={handleUserUpdate} />
        </>
      )}

      {profileUser && (
        <>
          {view ? (
            <>
              <Helmet>
                <title>
                  {profileUser.username} &middot;{" "}
                  {view.charAt(0).toUpperCase() + view.slice(1)} &middot;
                  Horror.ar
                </title>
              </Helmet>
              <UserView user={profileUser} view={view} key={view} />
            </>
          ) : (
            <>
              <Helmet>
                <title>
                  {profileUser.username ?? username} &middot; Horror.ar
                </title>
              </Helmet>
              {Object.keys(profileUser).length &&
              Object.keys(recent).length === 0 ? (
                isSelf ? (
                  <div className="emptyContent">
                    <p>
                      You have no activity yet. Start rating your favorite
                      horror films and shows!
                    </p>
                    <p>
                      Discover{" "}
                      <NavLink to="/items/popular">
                        what's popular today
                      </NavLink>
                      , explore{" "}
                      <NavLink to="/lists">our lists and genres</NavLink> or{" "}
                      <NavLink to="/">go back home</NavLink> to find more horror
                      films and shows.
                    </p>
                  </div>
                ) : (
                  <div className="emptyContent">
                    <p>{username} has no activity yet.</p>
                  </div>
                )
              ) : (
                <>
                  <h2 className="cSubtitle">
                    <NavLink to={"/user/" + profileUser.username + "/reviews"}>
                      Recent reviews
                      {profileUser?.stats?.reviews > 5 && <ChevronRight />}
                    </NavLink>
                  </h2>

                  {profileUser && recent.reviews && !recent.reviews.length ? (
                    <div className="emptyContent">
                      <p>
                        {isSelf ? (
                          <>You haven't</>
                        ) : (
                          <>
                            <strong>{profileUser.username}</strong> hasn't
                          </>
                        )}{" "}
                        published any reviews yet.
                      </p>
                    </div>
                  ) : (
                    <Reviews
                      total={profileUser?.stats?.reviews}
                      reviews={recent.reviews}
                    />
                  )}

                  <h2 className="cSubtitle">
                    <NavLink to={"/user/" + profileUser.username + "/lists"}>
                      Recent lists
                      {profileUser?.stats?.lists > 5 && <ChevronRight />}
                    </NavLink>
                  </h2>

                  {profileUser && recent.lists && !recent.lists.length ? (
                    <div className="emptyContent">
                      <p>
                        {isSelf ? (
                          <>You haven't</>
                        ) : (
                          <>
                            <strong>{profileUser.username}</strong> hasn't
                          </>
                        )}{" "}
                        created any lists yet.
                      </p>
                    </div>
                  ) : (
                    <Collections items={recent.lists} />
                  )}

                  {isSelf && (
                    <>
                      <h2 className="cSubtitle">
                        <NavLink
                          to={"/user/" + profileUser.username + "/recommended"}
                        >
                          Recommended for you
                          {recent?.recommendations?.[0] && <ChevronRight />}
                        </NavLink>
                      </h2>
                      {profileUser &&
                      recent.recommendations &&
                      !recent.recommendations.length ? (
                        <div className="emptyContent">
                          <p>
                            Start rating your favorite horror films and shows to
                            get fresh recommendations.
                          </p>
                        </div>
                      ) : (
                        <Posters
                          items={recent.recommendations}
                          horizontal={true}
                          more={{
                            path:
                              "/user/" + profileUser.username + "/recommended",
                          }}
                        />
                      )}
                    </>
                  )}

                  <h2 className="cSubtitle">
                    <NavLink to={"/user/" + profileUser.username + "/watched"}>
                      Recently watched
                      {recent?.watched?.[0] && <ChevronRight />}
                    </NavLink>
                  </h2>
                  {profileUser && recent.watched && !recent.watched.length ? (
                    <div className="emptyContent">
                      <p>
                        {isSelf ? (
                          <>You haven't</>
                        ) : (
                          <>
                            <strong>{profileUser.username}</strong> hasn't
                          </>
                        )}{" "}
                        watched any items yet.
                      </p>
                    </div>
                  ) : (
                    <Posters
                      items={recent.watched}
                      horizontal={true}
                      more={{
                        path: "/user/" + profileUser.username + "/watched",
                      }}
                    />
                  )}

                  {isSelf && (
                    <>
                      <h2 className="cSubtitle">
                        <NavLink
                          to={"/user/" + profileUser.username + "/watchlist"}
                        >
                          Watchlist
                          {recent?.watchlist?.[0] && <ChevronRight />}
                        </NavLink>
                      </h2>
                      {profileUser &&
                      recent.watchlist &&
                      !recent.watchlist.length ? (
                        <div className="emptyContent">
                          <p>Your watchlist is empty.</p>
                        </div>
                      ) : (
                        <Posters
                          items={recent.watchlist}
                          horizontal={true}
                          more={{
                            path:
                              "/user/" + profileUser.username + "/watchlist",
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </section>
  );
};

export default Profile;
