import { Blurhash } from "react-blurhash";
import ImgCdn from "../Utils/ImgCdn";

/**
 * Validation stuff
 */
const digitCharacters = [
  ...Array.from({ length: 10 }, (_, i) => i.toString()), // 0-9
  ...Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i)), // A-Z
  ...Array.from({ length: 26 }, (_, i) => String.fromCharCode(97 + i)), // a-z
  "#",
  "$",
  "%",
  "*",
  "+",
  ",",
  "-",
  ".",
  ":",
  ";",
  "=",
  "?",
  "@",
  "[",
  "]",
  "^",
  "_",
  "{",
  "|",
  "}",
  "~",
];

const decode83 = (str) => {
  let value = 0;
  for (let i = 0; i < str.length; i++) {
    const c = str[i];
    const digit = digitCharacters.indexOf(c);
    value = value * 83 + digit;
  }
  return value;
};

const validateBlurhash = (blurhash) => {
  if (!blurhash || blurhash.length < 6) return false;
  const sizeFlag = decode83(blurhash[0]);
  const numY = Math.floor(sizeFlag / 9) + 1;
  const numX = (sizeFlag % 9) + 1;
  const expectedLength = 4 + 2 * numX * numY;
  return blurhash.length === expectedLength;
};

const BlurhashImage = (props) => {
  // Dynamically import the image based on the prop value
  const defaultImg = props.t ? require(`../../img/${props.t}.png`) : null;

  return (
    <>
      {props.src && props.blurhash && validateBlurhash(props.blurhash) && (
        <Blurhash
          hash={props.blurhash}
          width={props.width}
          height={props.height}
        />
      )}
      {props.src ? (
        <ImgCdn
          src={props.src}
          alt={props.alt ?? ""}
          t={props.t}
          loading={props.loading ?? "lazy"}
          className={props.className ?? null}
          onClick={props.onClick ?? null}
        />
      ) : (
        <img
          src={defaultImg}
          alt=""
          width={props.width}
          height={props.height}
          className={props.className ?? null}
          loading={props.loading ?? "lazy"}
          onClick={props.onClick ?? null}
        />
      )}
    </>
  );
};

export default BlurhashImage;
