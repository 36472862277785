import { useState, useEffect } from "react";

function ImgCdn(props) {
  // Define image URL
  const [url, setUrl] = useState(null);
  const [srcset, setSrcset] = useState(null);
  const transparent =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=";

  useEffect(() => {
    let newUrl;
    if (props.src) {
      const imgSrc = props.src.startsWith("/")
        ? props.src.substring(1)
        : props.src;
      if (imgSrc.startsWith("https://")) {
        if (imgSrc.startsWith("https://media.horror.ar/media/")) {
          newUrl =
            "https://ik.imagekit.io/" +
            process.env.REACT_APP_CDN_ID +
            "/tr:n-" +
            props.t +
            "/" +
            imgSrc.replace("https://media.horror.ar/media/", "");
        } else {
          newUrl = imgSrc;
        }
      } else {
        newUrl =
          "https://ik.imagekit.io/" +
          process.env.REACT_APP_CDN_ID +
          "/tr:n-" +
          props.t +
          "/" +
          imgSrc;
      }
      setUrl(newUrl);
    }
  }, [props.src, props.t]);

  useEffect(() => {
    const coverSizes = [
      ["cover", "1440"],
      ["cover_lg", "720"],
      ["cover_md", "420"],
    ];

    let newSrcset = "";
    if (props.src) {
      if (props.t.startsWith("cover")) {
        newSrcset = coverSizes
          .map(([name, size]) => {
            const url =
              "https://ik.imagekit.io/" +
              process.env.REACT_APP_CDN_ID +
              "/tr:n-" +
              name +
              "/" +
              props.src;
            return `${url} ${size}w`;
          })
          .join(", ");
      }
    }

    setSrcset(newSrcset);
  }, [props.t, props.src]);

  return (
    <img
      src={url ?? transparent}
      alt={props.alt}
      loading={props.loading}
      className={props.className ?? null}
      onClick={props.onClick ?? null}
      srcSet={srcset}
    />
  );
}

export default ImgCdn;
