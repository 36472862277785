import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

import UserContext from "./UserContext";

function UserProvider({ children }) {
  const namespace = "https://horror.ar";
  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    loginWithPopup,
    logout,
    getIdTokenClaims,
    getAccessTokenSilently,
    getTokenWithPopup,
  } = useAuth0();

  const [accessToken, setAccessToken] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [queryParameters] = useSearchParams();

  const navigate = useNavigate();

  if (!isLoading && isAuthenticated) {
    user.username = user[`${namespace}/username`] ?? user.name;
  }

  /**
   * Get user data
   */
  useEffect(() => {
    // If the user hast just verified
    if (queryParameters.get("iss")) {
      setTimeout(function () {
        loginWithRedirect({
          prompt: "login",
        });
      });
    }

    // Else, get user again
    const getUserMetadata = async () => {
      if (!isLoading) {
        if (isAuthenticated) {
          setIsVerified(user.email_verified ? true : false);

          try {
            const access_token = await getAccessTokenSilently({
              ignoreCache: true,
              audience: process.env.REACT_APP_AUTH0_CLIENT_ID,
            });
            setAccessToken(access_token);
          } catch (e) {
            toast.error("Your session has expired.");
            setTimeout(function () {
              /*
              loginWithRedirect({
                prompt: "login",
              });
              */
              logout({ logoutParams: { returnTo: window.location.origin } });
            });
            console.log(e.message);
          }
        }
      }
    };

    getUserMetadata();
  }, [
    getIdTokenClaims,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    user,
    loginWithRedirect,
    getTokenWithPopup,
    logout,
    queryParameters,
  ]);

  /**
   * Return user profile
   */
  useEffect(() => {
    //setUserProfile(false);
    const cachedUserProfile = localStorage.getItem("userProfile");
    const expirationTime = localStorage.getItem("userProfile_expiration_time");
    const currentTime = new Date().getTime();

    if (cachedUserProfile && expirationTime && currentTime < expirationTime) {
      setUserProfile(JSON.parse(cachedUserProfile));
    } else {
      if (!isLoading) {
        if (user && isAuthenticated) {
          const userUrl = // Define item URL
            process.env.REACT_APP_API_URL +
            "/user/profile/?token=" +
            process.env.REACT_APP_API_KEY +
            "&user=" +
            user.sub;

          fetch(userUrl, { cache: "default" })
            .then((response) => response.json())
            .then((data) => {
              if (data.status !== "error") {
                localStorage.setItem("userProfile", JSON.stringify(data.user));

                // Set expiration time to 1 hourfrom now
                const expirationTime = new Date().getTime() + 60 * 60 * 1000;
                localStorage.setItem(
                  "userProfile_expiration_time",
                  expirationTime
                );

                setUserProfile(data.user);
              } else {
                // User not yet in the db
                setUserProfile({ profile: user });
              }
            })
            .catch(function (error) {
              setUserProfile(false);
              console.log(error);
            });
        }
      }
    }
  }, [isAuthenticated, isLoading, user, setUserProfile, navigate]);

  return (
    <UserContext.Provider
      value={{
        user,
        accessToken,
        isAuthenticated,
        isLoading,
        isVerified,
        loginWithRedirect,
        loginWithPopup,
        logout,
        getAccessTokenSilently,
        userProfile,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;
