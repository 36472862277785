import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import UserContext from "../Auth/UserContext";

import Score from "../Blocks/Score";
import ImgCdn from "../Utils/ImgCdn";
import ScoreStars from "../Blocks/ScoreStars";
import BlurhashImage from "../Utils/BlurhashImage";

import * as dayjs from "dayjs";

import "./Hero.scss";
import "./Item.scss";
import avatar from "../../img/person_lg.png";

export default function HeroItem(props) {
  const { isAuthenticated, user } = useContext(UserContext);

  const item = props.item ?? null;

  const [itemLink, setItemLink] = useState(null);
  useEffect(() => {
    if (item && item.type === "user") {
      setItemLink("/user/" + item.username);
    } else {
      setItemLink(null);
    }
  }, [item]);

  // If there's no item... goodbye
  if (!item) return false;

  // Hero class
  const classList = [
    "Hero",
    "Item",
    props.className,
    props.type ?? "",
    item.type,
    props.isLoading ? " Loading" : " Done",
    item.cover && "cover",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <>
      <header className={classList}>
        <figure className="media blurhash">
          {!props.isLoading &&
            item &&
            (item.cover ? (
              <BlurhashImage
                src={item.cover}
                alt={item.title}
                t="cover_lg"
                blurhash={item.cover_blurhash ?? null}
                width={720}
                height={405}
                loading="eager"
              />
            ) : item.poster ? (
              <BlurhashImage
                src={item.poster}
                alt={item.title}
                t="cover_lg"
                blurhash={item.poster_blurhash ?? null}
                width={720}
                height={405}
                loading="eager"
                className="poster"
              />
            ) : item.picture ? (
              <img src={item.picture} alt={item.name} className="poster" />
            ) : (
              ""
            ))}
        </figure>
        {item.type === "person" && (
          <figure className="avatar">
            {item.poster ? (
              <BlurhashImage
                src={item.poster}
                alt={item.title}
                t="person_lg"
                blurhash={item.poster_blurhash ?? null}
                width={120}
                height={120}
                loading="eager"
              />
            ) : (
              <img src={avatar} alt="" />
            )}
          </figure>
        )}
        {item.sub && (
          <figure className="avatar">
            {item.picture ? (
              <img src={item.picture} alt={item.name} />
            ) : (
              <img src={avatar} alt="" />
            )}
          </figure>
        )}
        <div className="header">
          {props.type === "film" && (
            <figure className={"blurhash poster " + item.type}>
              <BlurhashImage
                src={item.poster}
                alt={item.title}
                t="poster"
                blurhash={item.poster_blurhash ?? null}
                width="240"
                height="360"
              />
            </figure>
          )}
          <hgroup>
            {["genre", "collection"].includes(item.type) && (
              <div className="tSmall info">
                <span className="title">{item.type}</span>
              </div>
            )}
            {item.type === "review" && item.user.score && (
              <ScoreStars score={item.user.score} />
            )}
            <h1 className="tTitle">
              {itemLink !== null ? (
                <NavLink to={itemLink}>
                  {item.title ?? item.username ?? item.name ?? ""}
                </NavLink>
              ) : (
                item.title ?? item.username ?? item.name ?? ""
              )}
            </h1>
            {!["genre", "collection"].includes(item.type) && (
              <div className="tSmall info">
                {!props.isLoading ? (
                  <>
                    {(item.type === "film" || item.type === "show") && (
                      <>
                        {item.released && <Score score={item.score ?? null} />}
                        <span>{item.type}</span>
                        {item.year && (
                          <span>
                            {item.year}
                            {item.type === "show" &&
                              item.end &&
                              item.end !== item.date &&
                              "\u2014" + dayjs(item.end).format("YYYY")}
                          </span>
                        )}
                        {item.runtime && (
                          <span>
                            {item.runtime >= 60
                              ? `${Math.floor(item.runtime / 60)}h`
                              : ""}
                            {item.runtime % 60 !== 0
                              ? ` ${item.runtime % 60}m`
                              : ""}
                          </span>
                        )}
                      </>
                    )}
                    {item.type === "person" && (
                      <>
                        {item.birthday && (
                          <span>
                            {dayjs(item.birthday).format("YYYY")}
                            {item.deathday && (
                              <>
                                {" "}
                                &mdash; {dayjs(item.deathday).format("YYYY")}
                              </>
                            )}
                          </span>
                        )}
                        {item.origin && (
                          <span>{item.origin.split(/[,]+/).pop()}</span>
                        )}
                      </>
                    )}
                    {item.type === "user" && (
                      <ul>
                        <li>
                          <NavLink to={"/user/" + item.username + "/watched"}>
                            {item.stats.watched} Watched
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"/user/" + item.username + "/lists"}>
                            {item.stats.lists} List
                            {item.stats.lists !== 1 ? "s" : ""}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"/user/" + item.username + "/reviews"}>
                            {item.stats.reviews} Review
                            {item.stats.reviews !== 1 ? "s" : ""}
                          </NavLink>
                        </li>
                      </ul>
                    )}
                    {(item.type === "list" || item.type === "review") && (
                      <>
                        {item.user.picture && (
                          <NavLink to={"/user/" + item.user.username}>
                            <ImgCdn
                              src={item.user.picture}
                              alt={item.user.username}
                              t="person_md"
                              loading="lazy"
                              className="icon"
                            />
                          </NavLink>
                        )}
                        <span>
                          a {item.type} by{" "}
                          <NavLink to={"/user/" + item.user.username}>
                            {item.user.username}
                          </NavLink>
                        </span>
                        {isAuthenticated && user.sub === item.user.sub && (
                          <span className="you">You</span>
                        )}
                        {item.type === "review" &&
                          dayjs().to(dayjs(item.epoch * 1000))}
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
          </hgroup>
        </div>
      </header>
      {(item.type === "genre" || props.display === "genre") && (
        <div
          className={
            "HeroDescription " + (props.isLoading ? " Loading" : " Done")
          }
        >
          <p className="tMedium">{!props.isLoading ? item.description : ""}</p>
        </div>
      )}
    </>
  );
}
