import { useContext } from "react";
import UserContext from "./UserContext";

import "./AuthContent.scss";

export default function AuthContent({ children }) {
  const { isAuthenticated, isLoading, loginWithRedirect } =
    useContext(UserContext);

  return !isLoading && isAuthenticated ? (
    children
  ) : (
    <div className="AuthContent">
      <p className="buttons">
        <button className="outline" onClick={() => loginWithRedirect()}>
          Log in
        </button>{" "}
        or{" "}
        <button
          className="outline"
          onClick={() =>
            loginWithRedirect({
              authorizationParams: {
                screen_hint: "signup",
              },
            })
          }
        >
          Sign up
        </button>
      </p>
    </div>
  );
}
