import { useContext, useEffect, useCallback, useMemo } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { AppStatusContext } from "../components/Utils/AppStatus";
import UserContext from "../components/Auth/UserContext";
import Login from "../components/User/Login";
import Verify from "../components/User/Verify";

import "./Profile.scss";
import "../styles/__Fullscreen.scss";

const Profile = () => {
  const { pathname } = useLocation();
  const { username } = useParams();
  const navigate = useNavigate();

  const { setAppLoading } = useContext(AppStatusContext);

  const { user, isAuthenticated, isVerified, isLoading } =
    useContext(UserContext);

  /** Check URL */
  const handleCheckUrl = useCallback(() => {
    if (username && !pathname.startsWith("/user")) {
      navigate("/user/" + username);
    }
  }, [pathname, username, navigate]);

  useEffect(() => {
    handleCheckUrl();
  }, [handleCheckUrl]);

  const handleRedirect = useCallback(() => {
    if (!isLoading && user && isAuthenticated && isVerified) {
      navigate("/user/" + user.username);
    }
    setAppLoading(false);
  }, [user, isAuthenticated, isVerified, isLoading, navigate]);

  useEffect(() => {
    handleRedirect();
  }, [handleRedirect]);

  const renderContent = useMemo(() => {
    if (isLoading) {
      return <div className="LoadingUser" />;
    }

    if (isAuthenticated && !isVerified) {
      setAppLoading(false);
      return (
        <>
          <Helmet>
            <title>Verify your email &middot; Horror.ar</title>
          </Helmet>
          <Verify user={user} />
        </>
      );
    }

    return <Login />;
  }, [isLoading, isAuthenticated, isVerified, user]);

  return (
    <section
      id="Profile"
      className={
        isLoading ? "LoadingUser" : isAuthenticated ? "User" : "Fullscreen"
      }
    >
      {renderContent}
    </section>
  );
};

export default Profile;
