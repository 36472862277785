import React, { useState, useEffect, useRef, useContext } from "react";
import UserContext from "../Auth/UserContext";

import { NavLink } from "react-router-dom";

import BlurhashImage from "../Utils/BlurhashImage";
import Loader from "../Utils/Loader";
import ScoreStars from "../Blocks/ScoreStars";
import { MoreHorizontal, ThumbsUp, ThumbsDown } from "react-feather";

import "./Review.scss";
import "../Blocks/ScoreSomething.scss";

import * as dayjs from "dayjs";
import * as relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export default function Review(props) {
  const reviewRef = useRef(null);
  const [reviewHeight, setReviewHeight] = useState(0);
  const [reviewStatus, setReviewStatus] = useState("closed");

  // review height
  useEffect(() => {
    if (reviewRef.current)
      setReviewHeight(reviewRef.current.getBoundingClientRect().height);
  }, [reviewRef]);

  // Ratings
  const { isAuthenticated, user, getAccessTokenSilently } =
    useContext(UserContext);
  const [voting, setVoting] = useState(false);
  const [currentScore, setCurrentScore] = useState(props.review.score ?? 0);
  const [userVoted, setUserVoted] = useState(null);

  // User voted?
  useEffect(() => {
    if (props.userData?.reviews) {
      const reviewKey = props.userData.reviews.findIndex(
        (item) => Number(item.review) === Number(props.review.id)
      );
      if (reviewKey >= 0) {
        setUserVoted(props.userData.reviews[reviewKey].score);
      }
    }
  }, [props]);

  const handleRating = async (index) => {
    setVoting(true);

    if (isAuthenticated) {
      const access_token = await getAccessTokenSilently({
        ignoreCache: true,
      });

      const formData = new URLSearchParams();
      formData.append("access_token", access_token);
      formData.append("item", props.item.id);
      formData.append("review", props.review.id);
      formData.append("score", index);
      fetch(
        process.env.REACT_APP_API_URL +
          "/user/actions/?action=rateReview&token=" +
          process.env.REACT_APP_API_KEY,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.status !== "error") {
            const updatedScore =
              Number(currentScore) - Number(userVoted) + index;
            setCurrentScore(updatedScore);

            setUserVoted(index !== 0 ? index : false);
            setVoting(false);

            const updatedItemReviews = [...props.item.reviews.top] ?? {};
            const reviewKey = updatedItemReviews.findIndex(
              (item) => Number(item.id) === Number(props.review.id)
            );

            // Update in handler
            if (reviewKey >= 0) {
              updatedItemReviews[reviewKey].score = updatedScore;
              props.handleItemData("reviews.top", updatedItemReviews);
            }

            if (props.userData && props.handleUserData) {
              const updatedUserReviews = [...props.userData.genres];
              const reviewKey = updatedUserReviews.findIndex(
                (item) => Number(item.review) === Number(props.review.id)
              );

              // Update in handler
              if (reviewKey >= 0) {
                if (index === 0) {
                  updatedUserReviews.splice(reviewKey, 1);
                } else {
                  updatedUserReviews[reviewKey].score = index;
                }
              } else {
                if (index !== 0) {
                  updatedUserReviews.push({
                    review: Number(props.review.id),
                    score: index,
                  });
                }
              }

              props.handleUserData("reviews", updatedUserReviews);
            }
          }
          setVoting(false);
        });
    }
  };

  return (
    <article className={"Review" + (props.full ? " Full" : "")}>
      {props.review.item && props.review.item.poster && (
        <NavLink to={"/item/" + props.review.item.id} className="poster">
          <figure className="blurhash">
            <BlurhashImage
              src={props.review.item.poster ?? null}
              alt={props.review.item.title}
              t={"poster_md"}
              blurhash={props.review.item.poster_blurhash ?? null}
              width={240}
              height={360}
            />
          </figure>
        </NavLink>
      )}
      <div className="text">
        <header>
          {!props.full && !props.review.item && (
            <NavLink to={"/user/" + props.review.user.username}>
              <figure className="blurhash avatar">
                {props.review.user.picture && (
                  <BlurhashImage
                    src={props.review.user.picture}
                    alt={props.review.user.username}
                    t="person_md"
                    blurhash={null}
                    width={120}
                    height={120}
                  />
                )}
              </figure>
            </NavLink>
          )}
          <div className="content">
            <p className="title tMedium">
              {props.review.item && (
                <>
                  <NavLink to={"/item/" + props.review.item.id}>
                    {props.review.item.title}
                  </NavLink>
                </>
              )}
              <NavLink
                to={"/user/" + props.review.user.username}
                className={props.review.item ? "user" : ""}
              >
                {props.review.item && <small className="tSmall">by</small>}{" "}
                {props.review.user.username ?? props.review.user.nickname}
              </NavLink>
              {isAuthenticated && user.sub === props.review.user.sub && (
                <span className="you">You</span>
              )}
            </p>

            <div className="author tSmall">
              {props.review.user.score && (
                <ScoreStars score={props.review.user.score} />
              )}
              <NavLink to={"/review/" + props.review.id} className="date">
                {dayjs().to(dayjs(props.review.epoch * 1000))}
              </NavLink>
            </div>
          </div>
        </header>

        <div className="content">
          <div
            className={"review " + reviewStatus}
            style={{ "--review-height": reviewHeight + "px" }}
          >
            <div ref={reviewRef}>
              {!props.preview ? (
                props.review.review.split(/\n+/).map((paragraph, index) => {
                  const lines = paragraph.trim().split(/\n/);
                  return (
                    <p key={index}>
                      {lines.map((line, i) => (
                        <React.Fragment key={i}>
                          {line}
                          {i !== lines.length - 1 && <br />}
                        </React.Fragment>
                      ))}
                    </p>
                  );
                })
              ) : (
                <NavLink to={"/review/" + props.review.id}>
                  <p className="preview">
                    {props.review.review.substring(0, 240)}
                    {props.review.review.length > 240 && "..."}{" "}
                  </p>
                  <p className="tSmall more">View review</p>
                </NavLink>
              )}
            </div>

            {!props.preview && !props.full && (
              <button
                className="icon showMore"
                onClick={() => {
                  setReviewStatus("open");
                }}
              >
                <MoreHorizontal />
              </button>
            )}
          </div>

          {!props.preview && (
            <div
              className="ScoreSomething"
              data-voting={voting}
              data-enabled={
                isAuthenticated && user.sub !== props.review.user.sub
              }
            >
              <button
                className="icon small down"
                data-rated={userVoted === -1}
                onClick={() => handleRating(userVoted === -1 ? 0 : -1)}
              >
                <ThumbsDown />
              </button>
              <span className="score">
                {voting ? <Loader /> : currentScore}
              </span>
              <button
                className="icon small up"
                data-rated={userVoted === 1}
                onClick={() => handleRating(userVoted === 1 ? 0 : 1)}
              >
                <ThumbsUp />
              </button>
            </div>
          )}
        </div>
      </div>
    </article>
  );
}
