import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";

import { AppStatusContext } from "../components/Utils/AppStatus";

import "./Docs.scss";

const Docs = () => {
  const { doc } = useParams();
  const [content, setContent] = useState("");

  const { appLoading, setAppLoading } = useContext(AppStatusContext);

  useEffect(() => {
    const fetchDoc = async () => {
      try {
        const module = await import(`../docs/${doc}.md`);
        const response = await fetch(module.default);
        const text = await response.text();
        setContent(text);
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchDoc();
    setAppLoading(false);
  }, [doc, appLoading, setAppLoading]);

  return (
    <section id="Docs">
      {content ? (
        <ReactMarkdown>{content}</ReactMarkdown>
      ) : (
        <p>Loading document...</p>
      )}
    </section>
  );
};

export default Docs;
