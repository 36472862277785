import { useContext, useState } from "react";
import UserContext from "../Auth/UserContext";

import HeroItem from "../Hero/Item";
import Loader from "../Utils/Loader";

function Verify() {
  const { user, isAuthenticated, logout, getAccessTokenSilently } =
    useContext(UserContext);

  // Resend verification
  const [verifiying, setVerifiying] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const handleVerified = async () => {
    setVerifiying(true);
    if (isAuthenticated) {
      const access_token = await getAccessTokenSilently({
        ignoreCache: true,
      });

      const formData = new URLSearchParams();
      formData.append("access_token", access_token);
      fetch(
        process.env.REACT_APP_API_URL +
          "/user/actions/?action=resendVerification&token=" +
          process.env.REACT_APP_API_KEY,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.status !== "error") {
            setEmailSent(true);
          }
          setVerifiying(false);
        });
    }
  };

  return (
    <>
      <HeroItem item={user} type="small" />
      <div className="notVerified">
        <p className="tSubtitle">Pending verification</p>
        <p>
          Verify your account to start seeing your activity and recommendations.
        </p>
        <p>
          <button onClick={handleVerified} disabled={verifiying || emailSent}>
            {emailSent ? "Email sent!" : "Resend verification email"}
            {verifiying && <Loader />}
          </button>
        </p>
        <p>
          <button
            className="small outline"
            onClick={() => {
              logout({
                logoutParams: { returnTo: window.location.origin },
              });
              localStorage.removeItem("userProfile");
            }}
          >
            Log Out
          </button>
        </p>
      </div>
    </>
  );
}

export default Verify;
