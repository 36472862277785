import { useState, useEffect, useContext } from "react";

import UserContext from "../Auth/UserContext";

import Loader from "../Utils/Loader";
import { toast } from "react-toastify";

import { ThumbsUp, ThumbsDown } from "react-feather";

import "./ScoreSomething.scss";

export default function ScoreSomething(props) {
  // User
  const { loginWithRedirect, isAuthenticated, getAccessTokenSilently, user } =
    useContext(UserContext);
  const [userVoted, setUserVoted] = useState(null);

  const [voting, setVoting] = useState(false);

  const [item, setItem] = useState(null);
  const [currentScore, setCurrentScore] = useState(null);

  // Item
  useEffect(() => {
    if (props.item) {
      setItem(props.item);
      setCurrentScore(props.item.score);
    }
  }, [props]);

  // Voted?
  useEffect(() => {
    setUserVoted(props.userStats.voted);
  }, [props]);

  const handleRating = async (index) => {
    setVoting(true);

    if (isAuthenticated) {
      const access_token = await getAccessTokenSilently({
        ignoreCache: true,
      });

      const url =
        process.env.REACT_APP_API_URL +
        "/user/actions/?action=rate" +
        (item.type.charAt(0).toUpperCase() + item.type.slice(1)) +
        "&token=" +
        process.env.REACT_APP_API_KEY;

      const formData = new URLSearchParams();
      formData.append("access_token", access_token);
      formData.append(item.type, item.id);
      formData.append("item", item.item?.id ?? null);
      formData.append("score", index);
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData.toString(),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status !== "error") {
            const updatedScore =
              Number(currentScore) - Number(userVoted) + index;
            setCurrentScore(updatedScore);

            setUserVoted(index !== 0 ? index : false);
            setVoting(false);
          } else {
            if (
              res.message.startsWith(
                "Expiration Time (exp) claim error in the token"
              ) ||
              res.error === "invalid_grant"
            ) {
              toast.error("Your session has expired.");
              setTimeout(function () {
                loginWithRedirect({
                  prompt: "login",
                });
              });
            }
          }
          setVoting(false);
        });
    }
  };

  if (!item) return;

  return (
    <div
      className="ScoreSomething"
      data-hero={props.hero}
      data-voting={voting}
      data-enabled={isAuthenticated && user.sub !== item.user.sub}
    >
      <button
        className="icon small down"
        data-rated={userVoted === -1}
        onClick={() => handleRating(userVoted === -1 ? 0 : -1)}
      >
        <ThumbsDown />
      </button>
      <span className="score">{voting ? <Loader /> : currentScore ?? 0}</span>
      <button
        className="icon small up"
        data-rated={userVoted === 1}
        onClick={() => handleRating(userVoted === 1 ? 0 : 1)}
      >
        <ThumbsUp />
      </button>
    </div>
  );
}
