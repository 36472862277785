import { useContext, useState } from "react";

import UserContext from "../Auth/UserContext";

import { Camera } from "react-feather";

import { toast } from "react-toastify";
import Loader from "../Utils/Loader";

function Update(props) {
  const { isAuthenticated, getAccessTokenSilently } = useContext(UserContext);

  const [updating, setUpdating] = useState(false);
  const handleAvatarChange = async (event) => {
    setUpdating(true);

    if (isAuthenticated) {
      const access_token = await getAccessTokenSilently({
        ignoreCache: true,
      });

      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Data = reader.result.split(",")[1];

        const formData = new URLSearchParams();
        formData.append("access_token", access_token);

        formData.append("avatar", base64Data);

        fetch(
          process.env.REACT_APP_API_URL +
            "/user/actions/?action=updateAvatar&token=" +
            process.env.REACT_APP_API_KEY,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formData.toString(),
          }
        )
          .then((res) => res.json())
          .then((res) => {
            if (res.status !== "error") {
              props.handleUserUpdate("picture", res.picture);
              setTimeout(() => {
                setUpdating(false);
              }, 1000);
            } else {
              toast.error(res.message ?? "Error");
              setUpdating(false);
            }
          });
      };
    }
  };

  return (
    <form className={"editPicture" + (updating ? " disabled" : "")}>
      <label htmlFor="newAvatar">
        <span className="btn icon small round">
          {updating ? <Loader /> : <Camera />}
        </span>
      </label>
      <input
        type="file"
        id="newAvatar"
        name="newAvatar"
        onChange={handleAvatarChange}
      />
    </form>
  );
}

export default Update;
