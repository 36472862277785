import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import Collections from "../components/Items/Collections";
import Reviews from "../components/Items/PreviewReviews";
import Posters from "../components/Items/Posters";

const UserView = (props) => {
  const profileUser = props.user;
  const view = props.view;
  const [loadingItems, setLoadingItems] = useState(true);
  const [viewItems, setViewItems] = useState(false);

  useEffect(() => {
    if (view && profileUser?.sub) {
      setLoadingItems(true);
      const url =
        view === "lists" || view === "reviews"
          ? process.env.REACT_APP_API_URL +
            "/user/" +
            view +
            "/?token=" +
            process.env.REACT_APP_API_KEY +
            "&user=" +
            profileUser.sub
          : process.env.REACT_APP_API_URL +
            "/user/list/?token=" +
            process.env.REACT_APP_API_KEY +
            "&user=" +
            profileUser.sub +
            "&list=" +
            view;

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          if (data.status !== "error") {
            setViewItems(
              view === "lists"
                ? data.lists
                : view === "reviews"
                ? data.reviews
                : data.results?.items
            );
            setLoadingItems(false);
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
    } else {
      setViewItems(false);
      setLoadingItems(false);
    }
  }, [view, profileUser]);

  return (
    <>
      <h2 className="cSubtitle breadcrumb">
        <NavLink to={"/user/" + profileUser.username}>
          {profileUser.username}
        </NavLink>
        <em>{view}</em>
      </h2>
      {profileUser ? (
        !loadingItems && (!viewItems || Object.keys(viewItems).length < 1) ? (
          <div className="emptyContent">
            <p>
              There are no{" "}
              {view === "lists" || view === "reviews" ? view : "items"} here...
              yet.
            </p>
          </div>
        ) : (
          <>
            {view === "lists" ? (
              <Collections items={viewItems} />
            ) : view === "reviews" ? (
              <Reviews
                total={profileUser.stats.reviews ?? 0}
                reviews={viewItems}
              />
            ) : (
              <Posters
                items={viewItems}
                sortable={true}
                noParent={true}
                inUser={true}
              />
            )}
          </>
        )
      ) : null}
    </>
  );
};

export default UserView;
