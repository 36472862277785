import { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import UserContext from "../Auth/UserContext";

import BlurhashImage from "../Utils/BlurhashImage";

import poster from "../../img/poster_sm.png";

import "./Collections.scss";

function shuffleAndSliceItems(items) {
  if (!items || !items.length) {
    return [];
  }

  return items.map((item) => {
    if (item.items && Array.isArray(item.items)) {
      // If 'items' property exists and is an array, proceed to shuffle and slice
      const shuffledItems = item.items
        .slice() // Create a shallow copy to avoid mutating the original array
        .sort(() => Math.random() - 0.5)
        .slice(0, 4)
        .map((itm) => ({
          ...itm,
          poster: itm.poster || poster,
        }));

      // Create and return a new object with the shuffled items
      return {
        ...item,
        random: shuffledItems,
      };
    } else {
      // If 'items' property is missing or not an array, return the item as is
      return item;
    }
  });
}
// Empty collections
const EmptyItems = ({ n }) => {
  let emptyItems = [];
  for (let i = 0; i < n; ++i) {
    emptyItems.push(
      <li key={i} className="placeholder">
        <div className="item">
          <div className="posters imgPlaceholder"></div>
          <div className="text">
            <p className="item tMedium tPlaceholder">This is the title</p>
            <p className="tSmall tPlaceholder">Item type &middot; Total</p>
          </div>
        </div>
      </li>
    );
  }

  return <>{emptyItems}</>;
};

export default function Collections(props) {
  const { isAuthenticated, user } = useContext(UserContext);

  const [shuffledItems, setShuffledItems] = useState([]);
  useEffect(() => {
    setShuffledItems(shuffleAndSliceItems(props.items || []));
  }, [props.items]);

  return (
    <div className="Collections">
      <ul>
        {shuffledItems && shuffledItems.length > 0 ? (
          shuffledItems.map((item, index) => (
            <li key={index}>
              <NavLink
                to={"/" + (item.type ?? "collection") + "/" + item.id}
                className="item"
              >
                {item.items && (
                  <div className={"posters i" + item.random.length}>
                    {item.random.map((itm, i) => (
                      <figure className="blurhash" key={i}>
                        <BlurhashImage
                          src={itm.cover ?? itm.poster ?? null}
                          alt={itm.title}
                          t={"sq_sm"}
                          blurhash={
                            itm.cover_blurhash ?? itm.poster_blurhash ?? null
                          }
                          width={240}
                          height={240}
                        />
                      </figure>
                    ))}
                  </div>
                )}
                <div className="text">
                  <p className="item tMedium">{item.name}</p>
                  {item.type === "list" ? (
                    <p className="tSmall">
                      <span>
                        By{" "}
                        <strong>
                          {item.user.username ?? item.user.nickname}
                        </strong>
                      </span>
                      {isAuthenticated && user.sub === item.user.sub && (
                        <span className="you">You</span>
                      )}
                      <span>&middot;</span>
                      <span>{item.items.length} items</span>
                    </p>
                  ) : (
                    <p className="tSmall">
                      Collection &middot; {item.items?.length} items
                    </p>
                  )}
                </div>
              </NavLink>
            </li>
          ))
        ) : (
          <EmptyItems n="5" />
        )}
      </ul>
    </div>
  );
}
