import ImgCdn from "../Utils/ImgCdn";
import { X } from "react-feather";

import poster from "../../img/poster_sm.png";

import "./ModalHeader.scss";

export default function ModalHeader(props) {
  return (
    <div className="ModalHeader">
      <figure>
        {props.poster ? (
          <ImgCdn src={props.poster} alt={props.title} t="sq_sm" />
        ) : (
          <img src={poster} alt="" />
        )}
      </figure>
      <hgroup>
        <h3 className="tSmall">{props.subtitle}</h3>
        <h2>{props.title}</h2>
      </hgroup>
      <span className="close" onClick={props.onClose}>
        <X />
      </span>
    </div>
  );
}
