import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { AppStatusContext } from "../components/Utils/AppStatus";
import UserContext from "../components/Auth/UserContext";

import HeroItem from "../components/Hero/Item";
import Poster from "../components/Items/Poster";
import ItemActions from "../components/Blocks/ItemActions";

import "./Review.scss";

const Review = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { appLoading, setAppLoading } = useContext(AppStatusContext);

  /**
   * Get review
   */
  const [review, setReview] = useState(false);

  const url =
    process.env.REACT_APP_API_URL +
    "/review/?token=" +
    process.env.REACT_APP_API_KEY +
    "&id=" +
    id;

  useEffect(() => {
    fetch(url, { cache: "default" })
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== "error") {
          setReview(data.review);
          setAppLoading(false);
        } else {
          setReview({});
          navigate("/profile");
        }
      });
  }, [url]);

  /**
   * Userstats
   */
  const { user, isAuthenticated, isLoading } = useContext(UserContext);
  const [userStats, setUserStats] = useState(false);

  useEffect(() => {
    if (review && !isLoading && isAuthenticated && user) {
      const userUrl =
        process.env.REACT_APP_API_URL +
        "/user/review/?token=" +
        process.env.REACT_APP_API_KEY +
        "&id=" +
        id +
        "&user=" +
        user.sub;

      fetch(userUrl, { cache: "default" })
        .then((response) => response.json())
        .then((data) => {
          if (data.status !== "error") {
            setUserStats(data.results);
          }
        })
        .catch(function (error) {
          setUserStats({});
        });
    } else {
      setUserStats({});
    }
  }, [isAuthenticated, review, id]);

  /**
   * Return
   */

  return (
    <section id="Review">
      {review && (
        <>
          <Helmet>
            <title>
              {review.user?.username}'s review of {review.item?.title} &middot;
              Horror.ar
            </title>
          </Helmet>
          <ItemActions type="review" item={review} userStats={userStats} />
        </>
      )}

      <HeroItem item={review} isLoading={appLoading} type="small" />
      {review && (
        <>
          <div className="ReviewDetail">
            {review.review.split(/\n+/).map((paragraph, index) => {
              const lines = paragraph.trim().split(/\n/);
              return (
                <p key={index}>
                  {lines.map((line, i) => (
                    <React.Fragment key={i}>
                      {line}
                      {i !== lines.length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </p>
              );
            })}
          </div>

          <div className="Posters Grid Rows">
            <ul>
              <Poster {...review.item} />
            </ul>
          </div>
        </>
      )}
    </section>
  );
};

export default Review;
