import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { AppStatusContext } from "../components/Utils/AppStatus";

import HeroIntro from "../components/Hero/Intro";
import Posters from "../components/Items/Posters";
import Reviews from "../components/Items/PreviewReviews";

/**
 * Items definitions
 */
const urlBase =
  process.env.REACT_APP_API_URL +
  "/items/?token=" +
  process.env.REACT_APP_API_KEY;
const items = {
  popular: {
    url: urlBase + "&order_sort=ASC&order_type=popularity&max=40",
    title: "Popular horror",
    heading: "Today's most popular horror films & shows",
  },
  "coming-soon": {
    url: urlBase + "&order_type=upcoming&seasons=true&max=40",
    title: "Upcoming horror",
    heading: "Upcoming horror films & shows",
  },
  recent: {
    url: urlBase + "&order_type=recent&seasons=true&max=40",
    title: "Fresh horror",
    heading: "Recently released horror films & shows",
  },
  watched: {
    url: urlBase + "&order_type=watched&max=40",
    title: "Most watched",
    heading: "Most watched horror in the last 30 days",
  },
  watchlist: {
    url: urlBase + "&order_type=watchlist&max=40",
    title: "Most watchlisted",
    heading: "Top watchlisted horror in the last 30 days",
  },
  today: {
    url:
      process.env.REACT_APP_API_URL +
      "/today/?token=" +
      process.env.REACT_APP_API_KEY,
    title: null,
    heading: null,
  },
  reviews: {
    url:
      process.env.REACT_APP_API_URL +
      "/reviews/?token=" +
      process.env.REACT_APP_API_KEY +
      "&max=30",
    title: "Popular reviews",
    heading: "Recently popular & trending reviews",
  },
};

const Items = () => {
  const navigate = useNavigate();
  const { key } = useParams();
  const [current, setCurrent] = useState({});
  const { setAppLoading } = useContext(AppStatusContext);

  useEffect(() => {
    if (items.hasOwnProperty(key)) {
      setCurrent(items[key]);
      fetch(items[key].url, { cache: "default" })
        .then((response) => response.json())
        .then((data) => {
          console.log(key);
          const updatedCurrent = {
            ...items[key],
            items:
              key === "reviews"
                ? data.reviews
                : data.results ?? data.result.items,
            title:
              key === "today"
                ? "Season's Scares: " + data.result.title
                : items[key].title,
            heading:
              key === "today"
                ? "Season's Scares: A selection of " +
                  data.result.title +
                  " themed horror"
                : items[key].heading,
          };

          setCurrent(updatedCurrent);
          setAppLoading(false);
        });
    } else {
      navigate("/");
    }
  }, [key, navigate, setAppLoading]);

  return (
    <>
      {current.title && (
        <Helmet>
          <title>{current.title} &middot; Horror.ar</title>
        </Helmet>
      )}
      <section id="Items">
        <HeroIntro
          text={current.heading}
          items={current.items}
          className="small"
        />
        {key === "reviews" ? (
          <Reviews reviews={current.items} />
        ) : (
          <Posters
            items={current.items ?? {}}
            sortable={true}
            noParent={true}
            sortOrder={key === "coming-soon" ? "asc" : false}
          />
        )}
      </section>
    </>
  );
};

export default Items;
