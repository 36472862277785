import { useEffect, useState } from "react";
import { Star, MessageCircle, Layers, Play, Plus, Share } from "react-feather";

import ScoreSomething from "./ScoreSomething";
import "./ItemActions.scss";

function ItemActions({
  item,
  userData,
  userStats = null,
  setCurrentModal,
  type = "item",
}) {
  // Scroll
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const scrollThreshold = window.innerWidth > 800 ? 8 * 48 : 8 * 20;

    function handleScroll() {
      if (window.scrollY > scrollThreshold) {
        setScrolled(true);
        setIsHidden(true);
      } else {
        setScrolled(false);
        setIsHidden(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Visible?
  const [isHidden, setIsHidden] = useState(false);

  const handleToggle = () => {
    setIsHidden(!isHidden);
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        let title = `${item.title} (${
          item.type[0].toUpperCase() + item.type.slice(1)
        }${item.year !== null ? `, ${item.year}` : ""})`;
        let text = title;
        let url = "https://app.horror.ar/item/" + item.id;

        if (type === "list") {
          title = "List: " + item.name;
          text = title;
          url = "https://app.horror.ar/list/" + item.id;
        } else if (type === "review") {
          title = item.user.username + "'s review of " + item.item.title;
          text = title;
          url = "https://app.horror.ar/review/" + item.id;
        }

        await navigator.share({
          title,
          text,
          url,
        });
        //console.log("Successful share");
      } catch (error) {
        console.log("Error sharing", error);
      }
    } else {
      console.log("Web Share API not supported in this browser.");
    }
  };

  // Data
  const released = item.released ?? false;
  const hasLists = userData?.lists?.length > 0;

  return (
    <ul
      className={`actions ${scrolled ? "scrolled" : ""}  ${
        isHidden ? "hidden" : "visible"
      }`}
    >
      {type === "item" && (
        <>
          <li>
            <button
              className={"round " + (userData?.score ? "" : "over")}
              disabled={!released}
              onClick={() => setCurrentModal("modalRate")}
            >
              <Star />
            </button>
          </li>
          <li>
            <button
              className={"round " + (userData?.review?.review ? "" : "over")}
              disabled={!released}
              onClick={() => setCurrentModal("modalReview")}
            >
              <MessageCircle />
            </button>
          </li>
          <li>
            <button
              className={"round " + (hasLists ? "" : "over")}
              disabled={!item.id}
              onClick={() => setCurrentModal("modalLists")}
            >
              <Layers />
              {hasLists && <small>{userData.lists.length ?? 0}</small>}
            </button>
          </li>
          <li>
            <button
              className="round over"
              disabled={!item.trailer}
              onClick={() => setCurrentModal("modalTrailer")}
            >
              <Play />
            </button>
          </li>
        </>
      )}
      {type === "list" && (
        <>
          <li className="score">
            <ScoreSomething item={item} userStats={userStats} hero={true} />
          </li>
        </>
      )}
      {type === "review" && (
        <>
          <li className="score">
            <ScoreSomething item={item} userStats={userStats} hero={true} />
          </li>
        </>
      )}
      {navigator.share && (
        <li>
          <button className="round over" onClick={handleShare}>
            <Share />
          </button>
        </li>
      )}
      <li className="toggle">
        <button className="round over" onClick={handleToggle}>
          <Plus />
        </button>
      </li>
    </ul>
  );
}

export default ItemActions;
