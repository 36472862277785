import { useContext, useState, useEffect, useRef } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

import BlurhashImage from "../components/Utils/BlurhashImage";
import { MoreHorizontal } from "react-feather";

import HeroIntro from "../components/Hero/Intro";
import Collections from "../components/Items/Collections";

import { AppStatusContext } from "../components/Utils/AppStatus";
import AppContext from "../components/Utils/AppContext";

import "./Lists.scss";

export default function Lists() {
  const { pathname } = useLocation();
  const { setAppLoading } = useContext(AppStatusContext);
  /**
   * Lists stuff
   */

  const [lists, setLists] = useState({});

  const url =
    process.env.REACT_APP_API_URL +
    "/lists/?token=" +
    process.env.REACT_APP_API_KEY;

  useEffect(() => {
    fetch(url, { cache: "default" })
      .then((response) => response.json())
      .then((data) => {
        setLists(data.lists);
        setAppLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setAppLoading(false);
      });
  }, [url, pathname]);

  /**
   * Genres stuff
   */
  const { genres, genresLoading } = useContext(AppContext);
  const genresRef = useRef(null);
  const [genresHeight, setGenresHeight] = useState(0);
  const [genresStatus, setGenresStatus] = useState("closed");

  // Genres height
  useEffect(() => {
    if (genresRef.current)
      setGenresHeight(genresRef.current.getBoundingClientRect().height);
  }, [genresRef, genresLoading]);

  // Reset on path change
  useEffect(() => {
    setGenresStatus(pathname === "/genres" ? "open" : "closed");
  }, [pathname]);

  // Cover items
  const [items, setItems] = useState([{}]);
  useEffect(() => {
    const cachedHome = localStorage.getItem("home");
    if (cachedHome) {
      setItems(JSON.parse(cachedHome).popular);
    }
  }, []);

  return (
    <section id="Lists">
      <Helmet>
        <title>
          {pathname === "/lists" ? "Lists" : "Genres"} &middot; Horror.ar
        </title>
      </Helmet>
      <HeroIntro
        text={
          pathname === "/lists"
            ? "Explore our community-curated horror lists"
            : "Explore films and shows grouped by horror subgenres"
        }
        items={items}
        className="small"
      />

      <>
        {pathname === "/lists" && (
          <>
            <h2 className="cSubtitle">Popular lists</h2>
            <Collections items={lists} />
            <h2 className="cSubtitle">Genres</h2>
          </>
        )}

        {!genresLoading && genres && (
          <div
            className={"genres " + genresStatus}
            style={{ "--genres-height": genresHeight + "px" }}
          >
            <ul ref={genresRef}>
              {genres.map((item, i) => (
                <li key={i}>
                  <NavLink to={"/genre/" + item.id}>
                    {item.cover && (
                      <figure className="blurhash">
                        <BlurhashImage
                          src={item.cover}
                          alt={item.name}
                          t="sq_md"
                          blurhash={item.cover_blurhash ?? null}
                          width={240}
                          height={240}
                        />
                      </figure>
                    )}
                    <div className="text">
                      <p className="tMedium">{item.title}</p>
                      <p className="description">
                        {item.description.substring(0, 200)}
                        {item.description > 200 && "..."}{" "}
                      </p>
                    </div>
                  </NavLink>
                </li>
              ))}
            </ul>
            <button
              className="icon showMore"
              onClick={() => {
                setGenresStatus("open");
              }}
            >
              <MoreHorizontal />
            </button>
          </div>
        )}
      </>
    </section>
  );
}
