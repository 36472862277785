import React, { useEffect, useState } from "react";
import "./EmojiScore.scss";

const EmojiScore = ({ score }) => {
  const ratings = ["awful", "bad", "meh", "good", "great"];

  const [icon, setIcon] = useState("");

  useEffect(() => {
    const fetchDoc = async () => {
      try {
        const module = await import(`../../img/scores/${score}.svg`);
        const response = await fetch(module.default);
        const content = await response.text();
        setIcon(content);
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchDoc();
  }, [score]);

  return (
    <span
      className="EmojiScore"
      aria-label={ratings[score]}
      dangerouslySetInnerHTML={{ __html: icon }}
    />
  );
};

export default EmojiScore;
