import { useContext, useState, useEffect } from "react";

import { AppStatusContext } from "../components/Utils/AppStatus";
import UserContext from "../components/Auth/UserContext";

import { Plus } from "react-feather";
import AuthContent from "../components/Auth/AuthContent";
import Loader from "../components/Utils/Loader";

import "./Suggest.scss";
import { NavLink } from "react-router-dom";

const Suggest = () => {
  const { setAppLoading } = useContext(AppStatusContext);

  const { isAuthenticated, isLoading, getAccessTokenSilently } =
    useContext(UserContext);

  useEffect(() => {
    setAppLoading(false);
  }, [setAppLoading, isLoading]);

  // Form fields
  const [title, setTitle] = useState("");
  const [year, setYear] = useState("");
  const [type, setType] = useState("film");
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    if (isAuthenticated) {
      const access_token = await getAccessTokenSilently({
        ignoreCache: true,
      });

      const formData = new URLSearchParams();
      formData.append("access_token", access_token);
      formData.append("title", title);
      formData.append("year", year);
      formData.append("type", type);
      // Send the suggestion to the server
      fetch(
        process.env.REACT_APP_API_URL +
          "/user/actions/?action=suggest&token=" +
          process.env.REACT_APP_API_KEY,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.status !== "error") {
            // Suggestion submitted successfully
            setSubmitted(true);
          } else {
            // Handle error response here
            // console.error("Error:", res.message);
          }
          setSubmitting(false);
        });
    }
  };

  return (
    <section id="Suggest">
      {submitted ? (
        <div className="end">
          <h2 className="tTitle">Suggestion submitted!</h2>
          <p>
            Thank you for your suggestion. Our moderators will review it as soon
            as possible.
          </p>
          <p className="buttons">
            <button
              className="small subtle"
              onClick={() => window.location.reload()}
            >
              Add another one
            </button>{" "}
            <NavLink to="/" className="btn small">
              Go back home
            </NavLink>
          </p>
        </div>
      ) : (
        <div>
          <h2 className="tTitle">Suggest a film or show</h2>
          <p className="tMedium">
            Know of a horror film or show we don't have in our database? Share
            it below, and our moderators will review and add it to the site!
          </p>
          <AuthContent>
            <form onSubmit={handleSubmit}>
              <div className="type">
                <label>Type:</label>
                <label htmlFor="film">
                  {" "}
                  <input
                    type="radio"
                    id="film"
                    name="type"
                    value="film"
                    checked={type === "film" || !type}
                    onChange={() => setType("film")}
                  />{" "}
                  Film
                </label>
                <label htmlFor="show">
                  <input
                    type="radio"
                    id="show"
                    name="type"
                    value="show"
                    checked={type === "show"}
                    onChange={() => setType("show")}
                  />{" "}
                  Show
                </label>
              </div>
              <div>
                <label htmlFor="title">Title:</label>
                <input
                  type="text"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Item title"
                  required
                />
              </div>

              <div>
                <label htmlFor="year">Year:</label>
                <input
                  type="text"
                  pattern="[0-9]{4}"
                  id="year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  placeholder="2023"
                  required
                  maxLength={4}
                />
              </div>
              <div className="submit">
                <button
                  className="large"
                  type="submit"
                  disabled={submitting ? true : false}
                >
                  Submit
                  {submitting ? <Loader /> : <Plus />}
                </button>
              </div>
            </form>
          </AuthContent>
        </div>
      )}
    </section>
  );
};

export default Suggest;
