import { Star } from "react-feather";

import "./ScoreStars.scss";

export default function ScoreStars({ score }) {
  let rows = [];
  for (let i = 0; i < 5; i++) {
    let style = i - 1 >= score ? "" : "filled";
    rows.push(<Star className={style} key={i} />);
  }
  return (
    <div className="ScoreStars" data-score={score}>
      {rows}
    </div>
  );
}
