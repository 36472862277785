import { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";

import UserContext from "../Auth/UserContext";
import BlurhashImage from "../Utils/BlurhashImage";
import { Eye, Bookmark } from "react-feather";

import * as dayjs from "dayjs";

function Poster(props) {
  // Image definitions
  const [image, setImage] = useState(null);
  const [imageBlurhash, setImageBlurhash] = useState(null);

  useEffect(() => {
    setImage(props.season_poster ?? props.poster ?? props.cover ?? null);
    setImageBlurhash(
      props.season_poster_blurhash ??
        props.poster_blurhash ??
        props.cover_blurhash ??
        null
    );
  }, [props]);

  // Watched?
  const [watched, setWatched] = useState(false);
  const [watchlist, setWatchlist] = useState(false);
  const { isAuthenticated, isLoading, userProfile } = useContext(UserContext);

  useEffect(() => {
    if (
      !isLoading &&
      isAuthenticated &&
      userProfile &&
      (props.type === "show" || props.type === "film")
    ) {
      userProfile.profile.watched &&
        setWatched(userProfile.profile.watched.includes(props.id));
      userProfile.profile.watchlist &&
        setWatchlist(userProfile.profile.watchlist.includes(props.id));
    }
  }, [props, isLoading, isAuthenticated, userProfile]);

  return (
    <li>
      <NavLink
        className="item"
        to={"/" + (props.type === "genre" ? "genre" : "item") + "/" + props.id}
      >
        {watched && (
          <span className="userList watched">
            <Eye />
          </span>
        )}
        {watchlist && (
          <span className="userList watchlist">
            <Bookmark />
          </span>
        )}

        <figure className={"blurhash " + props.type}>
          <BlurhashImage
            src={image}
            alt={props.title}
            t={props.type === "genre" ? "cover_md" : "poster_lg"}
            blurhash={imageBlurhash ?? null}
            width={240}
            height={360}
          />
        </figure>

        {props.ranking && <span className="number">{props.index}</span>}
        <p>
          <strong>{props.title}</strong>
          {(props.type === "show" || props.type === "film") && (
            <span className="tSmall">
              {props.season ? (
                <>
                  Season {props.season} &middot;{" "}
                  {dayjs(props.date).format("YYYY")}
                </>
              ) : (
                <>
                  {props.type} {props.year !== null && ` \u00B7 ${props.year}`}
                </>
              )}
            </span>
          )}
        </p>
      </NavLink>
    </li>
  );
}

export default Poster;
