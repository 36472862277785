import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Blurhash } from "react-blurhash";
import ImgCdn from "../Utils/ImgCdn";
import { MoreHorizontal } from "react-feather";

import poster from "../../img/person_md.png";

import "./People.scss";

export default function People(props) {
  const [showMore, setShowMore] = useState(props.limit ? "" : true);

  // Format birthday

  const formatDateInfo = (item) => {
    const calculateAge = (birthDate, endDate) => {
      const start = new Date(birthDate);
      const end = new Date(endDate);
      let age = end.getFullYear() - start.getFullYear();
      const monthDifference = end.getMonth() - start.getMonth();
      if (
        monthDifference < 0 ||
        (monthDifference === 0 && end.getDate() < start.getDate())
      ) {
        age--;
      }
      return age;
    };

    const birthYear = new Date(item.birthday).getFullYear();

    if (item.deathday) {
      const deathYear = new Date(item.deathday).getFullYear();
      const ageAtTimeOfDeath = calculateAge(item.birthday, item.deathday);
      return `${birthYear}-${deathYear} (at age ${ageAtTimeOfDeath})`;
    } else {
      const currentAge = calculateAge(item.birthday, new Date());
      return `${birthYear} (${currentAge} years old)`;
    }
  };

  // gid (default), horizontal, small
  return (
    <div
      className={
        "People " + (props.type ?? "Horizontal") + (showMore && " More")
      }
      style={{ "--total": props.items.length }}
    >
      {props.items && props.items[0] && (
        <ul>
          {props.items.map((item, index) => (
            <li key={index}>
              <NavLink to={"/person/" + item.id}>
                <figure className="blurhash avatar">
                  {item.poster ? (
                    <>
                      {item.poster_blurhash && (
                        <Blurhash
                          hash={item.poster_blurhash}
                          width={240}
                          height={240}
                        />
                      )}
                      <ImgCdn src={item.poster} alt={item.name} t="person_md" />
                    </>
                  ) : (
                    <img src={poster} alt="" />
                  )}
                </figure>
                <p>
                  <strong>{item.name}</strong>
                  {props.type !== "Small" && item.role && (
                    <span>{item.role}</span>
                  )}
                  {props.birthday && !item.role && (
                    <span>{formatDateInfo(item)}</span>
                  )}
                </p>
              </NavLink>
            </li>
          ))}
        </ul>
      )}
      {props.limit && props.limit < props.items.length && (
        <button
          className="icon showMore"
          onClick={() => {
            setShowMore(1);
          }}
        >
          <MoreHorizontal />
          <small className="tSmall">
            {props.items.length - props.limit} more
          </small>
        </button>
      )}
    </div>
  );
}
