import { useRef, useContext, useState, useEffect } from "react";
import { Plus, Eye, Bookmark, User, Lock, Slash } from "react-feather";

import ImgCdn from "../Utils/ImgCdn";
import Loader from "../Utils/Loader";

import UserContext from "../Auth/UserContext";

import "./UserLists.scss";

/**
 * List
 */
function UserList(props) {
  const { user, userProfile } = useContext(UserContext);

  return (
    <li
      className={(
        (props.active ? "active" : "") +
        " " +
        (props.type === "default" ? "default " + props.name : "custom")
      ).trim()}
      data-loading={props.loading}
      data-disabled={props.disabled}
      data-index={props.index}
      data-active={props.active}
      onClick={(e) => {
        props.action(e, props.index);
      }}
    >
      <label>
        <div className="icon">
          {props.name === "watched" ? (
            <Eye />
          ) : props.name === "watchlist" ? (
            <Bookmark />
          ) : props.name === "not-interested" ? (
            <Slash />
          ) : userProfile.profile.picture ? (
            <ImgCdn
              src={userProfile.profile.picture}
              alt={userProfile.profile.username}
              t="person_md"
              loading="lazy"
              className="avatar"
            />
          ) : (
            <User />
          )}
        </div>
        <div className="name">
          <span className="title">
            {props.type === "default"
              ? props.name.charAt(0).toUpperCase() +
                props.name.slice(1).replace(/-/g, " ")
              : props.name}{" "}
            {props.disabled && <Lock />}
          </span>
          <span className="number tSmall">{props.items} items</span>
        </div>
        <div className="action">
          {props.loading === true ? (
            <Loader />
          ) : (
            <input
              type="checkbox"
              className="list"
              defaultChecked={props.active && "checked"}
            />
          )}
        </div>
      </label>
    </li>
  );
}

/**
 * Show lists
 */

export default function UserLists(props) {
  const {
    user,
    isAuthenticated,
    isLoading,
    userProfile,
    getAccessTokenSilently,
  } = useContext(UserContext);
  const [userLists, setUserLists] = useState({});

  /**
   * Load lists
   */

  useEffect(() => {
    if (!isLoading && user && isAuthenticated && userProfile) {
      const userLists =
        Array.isArray(userProfile.lists) && userProfile.lists.length > 0
          ? userProfile.lists.map((list, i) => ({
              index: i,
              name: list.name,
              list_id: Number(list.id),
              type: list.type,
              items: list.items,
              active: false,
              disabled:
                (list.name === "not-interested" &&
                  (props.userData.score !== null ||
                    props.userData.lists.some(
                      (item) => item.list === "watched"
                    ) ||
                    props.userData.lists.some(
                      (item) => item.list === "watchlist"
                    ))) ||
                (list.name === "watched" &&
                  (!props.item.released ||
                    props.userData.score !== null ||
                    props.userData.lists.some(
                      (item) => item.list === "not-interested"
                    ))) ||
                (list.name === "watchlist" &&
                  (props.userData.score !== null ||
                    props.userData.lists.some(
                      (item) => item.list === "not-interested"
                    ))),
            }))
          : [
              {
                index: 0,
                items: 0,
                list_id: 0,
                active: false,
                disabled: false,
                name: "not-interested",
                type: "default",
              },
              {
                index: 1,
                items: 0,
                list_id: 0,
                active: false,
                disabled: false,
                name: "watched",
                type: "default",
              },
              {
                index: 2,
                items: 0,
                list_id: 0,
                active: false,
                disabled: false,
                name: "watchlist",
                type: "default",
              },
            ];

      // Check if userData.lists exists and isn't empty before updating the userLists
      const updatedUserLists =
        props.userData.lists && props.userData.lists.length > 0
          ? userLists.map((list) => {
              const userDataList = props.userData.lists.find(
                (userDataList) =>
                  (userDataList.list_id === null &&
                    userDataList.list === list.name) ||
                  (userDataList.list_id === list.list_id &&
                    userDataList.list === list.type)
              );
              return userDataList ? { ...list, active: true } : list;
            })
          : userLists;

      setUserLists(updatedUserLists);
    }
  }, [isAuthenticated, isLoading, user, userProfile, props]);

  /**
   * Add/remove from lists
   */
  async function handleClick(e, index) {
    e.preventDefault();
    userLists[index].loading = true;
    setUserLists([...userLists]);

    const checked = String(e.currentTarget.getAttribute("data-active"));

    const action = checked === "true" ? "delete" : "add";

    const selectedList =
      userLists[index].list_id !== 0
        ? Number(userLists[index].list_id)
        : userLists[index].name;

    if (isAuthenticated) {
      const access_token = await getAccessTokenSilently({
        ignoreCache: true,
      });

      const formData = new URLSearchParams();
      formData.append("access_token", access_token);
      formData.append("action", action);
      formData.append("item", props.item.id);
      formData.append("list", selectedList);

      fetch(
        process.env.REACT_APP_API_URL +
          "/user/actions/?action=itemList&token=" +
          process.env.REACT_APP_API_KEY,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.status !== "error") {
            // Update userData
            if (props.userData && props.handleUserData) {
              const updatedUserLists = [...props.userData.lists];

              // Find item in userLists
              const listKey = updatedUserLists.findIndex((obj) =>
                obj.list_id !== null
                  ? obj.list_id === userLists[index].list_id
                  : obj.list === userLists[index].name
              );

              // Update in handler
              if (listKey >= 0) {
                updatedUserLists.splice(listKey, 1);
              } else {
                updatedUserLists.push({
                  list:
                    userLists[index].list_id !== 0
                      ? userLists[index].type
                      : userLists[index].name,
                  list_id:
                    userLists[index].list_id !== 0
                      ? userLists[index].list_id
                      : null,
                });
              }
              props.handleUserData("lists", updatedUserLists);

              // Update number in profile lists
              userProfile.lists[userLists[index].index] = {
                ...userProfile.lists[userLists[index].index],
                items:
                  checked === "true"
                    ? userProfile.lists[userLists[index].index].items - 1
                    : userProfile.lists[userLists[index].index].items + 1,
              };

              // Update in stats
              if (userProfile.profile.stats[userLists[index].name]) {
                userProfile.profile.stats[userLists[index].name] =
                  checked === "true"
                    ? userProfile.profile.stats[userLists[index].name] - 1
                    : userProfile.profile.stats[userLists[index].name] + 1;
              }
            }
          }
        });
    }
  }

  /**
   * List creation
   */
  const inputElement = useRef(null);
  const [showForm, setShowForm] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [creating, setCreating] = useState(false);

  function toggleForm() {
    setShowForm(!showForm);
    if (!showForm && inputElement.current) {
      inputElement.current.focus();
    }
  }

  // Handle list creation
  async function handleNewList(e, index) {
    e.preventDefault();
    setCreating(true);
    setErrorText(false);

    const list_name = e.target.elements.list_name.value;

    if (isAuthenticated) {
      const access_token = await getAccessTokenSilently({
        ignoreCache: true,
      });

      const formData = new URLSearchParams();
      formData.append("access_token", access_token);
      formData.append("list", list_name);

      fetch(
        process.env.REACT_APP_API_URL +
          "/user/actions/?action=createList&token=" +
          process.env.REACT_APP_API_KEY,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.status !== "error") {
            // Update profile lists
            userProfile.lists.push({
              id: res.id,
              items: 0,
              name: list_name,
              type: "custom",
              updated: null,
            });

            // updateUserLists
            const updatedUserLists = [...props.userData.lists];
            props.handleUserData("lists", updatedUserLists);

            setCreating(false);
            setShowForm(false);
            inputElement.current.value = "";
          } else {
            setErrorText(res.message);
            setCreating(false);
          }
        });
    }
  }

  return (
    <div className="UserLists">
      <ul data-show={showForm}>
        {Object.keys(userLists).length &&
          userLists.map((list, i) => (
            <UserList key={i} index={i} {...list} action={handleClick} />
          ))}
      </ul>
      <p className={"add" + (showForm ? " open" : "")}>
        <button className="outline" onClick={toggleForm}>
          <span>Create a new list</span>
          <Plus />
        </button>
      </p>
      <form
        className="newList"
        onSubmit={handleNewList}
        data-loading={creating}
        data-show={showForm}
      >
        <input
          type="text"
          name="list_name"
          id="list_name"
          placeholder="List name"
          required
          ref={inputElement}
        />
        <button className={creating ? "round disabled" : "round"}>
          {creating ? <Loader /> : <Plus />}
        </button>
      </form>
      {errorText && <p className="tError">{errorText}</p>}
    </div>
  );
}
