import { useState, useEffect } from "react";
import CountUp from "react-countup";
import "./Percentage.scss";
const Percentage = (props) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount < props.stats.percentage) {
          return prevCount + 1;
        } else {
          clearInterval(interval);
          return prevCount;
        }
      });
    }, 500 / props.stats.percentage);

    return () => clearInterval(interval);
  }, [props.stats.percentage]);

  return (
    <div className="Percentage">
      <div className="text tSmall">
        <p>
          <strong>
            <CountUp end={props.stats.watched ?? 0} duration={0.5} />
          </strong>{" "}
          of{" "}
          <strong>
            <CountUp end={props.stats.total ?? 0} duration={0.5} />
          </strong>{" "}
          watched
        </p>
        <div className="percentage">
          <CountUp end={props.stats.percentage ?? 0} duration={0.5} />
          <small>%</small>
        </div>
      </div>
      <div className="progress">
        <p
          data-progress={props.stats.percentage}
          style={{ width: count + "%" }}
        >
          {props.stats.percentage ?? 0}%
        </p>
      </div>
    </div>
  );
};

export default Percentage;
