import { useContext, useState, useEffect, useLayoutEffect } from "react";
import { useNavigate, useParams, NavLink } from "react-router-dom";
import { Blurhash } from "react-blurhash";

import UserContext from "../components/Auth/UserContext";

import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  useInstantSearch,
  SearchBox,
  InfiniteHits,
  Highlight,
  RefinementList,
  CurrentRefinements,
  ClearRefinements,
} from "react-instantsearch-hooks-web";

import { createInsightsMiddleware } from "instantsearch.js/es/middlewares";

import ImgCdn from "../components/Utils/ImgCdn";

import {
  Search as IconSearch,
  Layers as IconLayers,
  Tag as IconTag,
  Eye,
  Bookmark,
} from "react-feather";

import { AppStatusContext } from "../components/Utils/AppStatus";
import { CustomRangeSlider } from "../components/Blocks/RangeSlider";

import poster from "../img/poster_md.png";

import "./Search.scss";
import "./SearchFilters.scss";

const searchClient = algoliasearch(
  "9MWY8YVYFA",
  "ac9552a32afcd307d677fa9f85621ade"
);

function NoResultsBoundary({ children, fallback }) {
  const { results } = useInstantSearch();

  // The `__isArtificial` flag makes sure not to display the No Results message
  // when no hits have been returned yet.
  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    );
  }

  return children;
}

function NoResults() {
  const { indexUiState } = useInstantSearch();
  const { appLoading, setAppLoading } = useContext(AppStatusContext);

  useEffect(() => {
    setAppLoading(false);
  }, [appLoading, setAppLoading]);

  return (
    <div className="noResults">
      <p>
        Sorry, we couldn't find any results for <q>{indexUiState.query}</q>
      </p>
      <p>
        <a href="/suggest" className="btn outline">
          Suggest a film or show
        </a>
      </p>
    </div>
  );
}

function InsightsMiddleware() {
  const { use } = useInstantSearch();
  useLayoutEffect(() => {
    const middleware = createInsightsMiddleware({
      insightsClient: window.aa,
      insightsInitParams: {
        useCookie: true,
      },
    });

    return use(middleware);
  }, [use]);

  return null;
}

function Hit({ hit, sendEvent }) {
  const { appLoading, setAppLoading } = useContext(AppStatusContext);

  const { uiState } = useInstantSearch();
  const navigate = useNavigate();
  const query = uiState.items.query ?? null;

  // Watched?
  const [watched, setWatched] = useState(false);
  const [watchlist, setWatchlist] = useState(false);
  const { isAuthenticated, isLoading, userProfile } = useContext(UserContext);

  useEffect(() => {
    if (
      !isLoading &&
      isAuthenticated &&
      userProfile &&
      (hit.type === "Show" || hit.type === "Film")
    ) {
      userProfile.profile.watched &&
        setWatched(userProfile.profile.watched.includes(hit.id));
      userProfile.profile.watchlist &&
        setWatchlist(userProfile.profile.watchlist.includes(hit.id));
    }
  }, [hit, isLoading, isAuthenticated, userProfile]);

  useEffect(() => {
    setAppLoading(false);
  }, [appLoading, setAppLoading]);

  return (
    <>
      {hit.type === "Collection" ? (
        <NavLink
          className="collection icon"
          to={"/collection/" + hit.id}
          onClick={() => {
            sendEvent("click", hit, "Collection selected");
            query && navigate(`/search/${query}`);
          }}
        >
          <figure>
            <IconLayers />
          </figure>
          <p>
            <span className="tSmall">Collection</span>
            <Highlight attribute="title" hit={hit} />
          </p>
        </NavLink>
      ) : hit.type === "Genre" ? (
        <NavLink
          className="genre icon"
          to={"/genre/" + hit.id}
          onClick={() => {
            sendEvent("click", hit, "Genre selected");
            query && navigate(`/search/${query}`);
          }}
        >
          <figure>
            <IconTag />
          </figure>
          <p>
            <span className="tSmall">Genre</span>
            <Highlight attribute="title" hit={hit} />
          </p>
        </NavLink>
      ) : (
        <NavLink
          className="item"
          to={"/item/" + hit.id}
          onClick={() => {
            sendEvent("click", hit, "Item selected");
            //console.log(query);
            query && navigate(`/search/${query}`);
          }}
        >
          <figure className="blurhash">
            {hit.poster ? (
              <>
                {hit.poster_blurhash && (
                  <Blurhash hash={hit.poster_blurhash} width={64} height={96} />
                )}
                <ImgCdn
                  src={hit.poster}
                  alt={hit.title}
                  t="poster_md"
                  loading="lazy"
                />
              </>
            ) : (
              <img src={poster} alt="" />
            )}
          </figure>
          {watched && (
            <span className="userList watched">
              <Eye />
            </span>
          )}
          {watchlist && (
            <span className="userList watchlist">
              <Bookmark />
            </span>
          )}
          <p>
            <span className="tSmall">
              {hit.type} {hit.year && " \u00b7 " + hit.year}
            </span>
            <Highlight attribute="title" hit={hit} />
          </p>
        </NavLink>
      )}
    </>
  );
}

function Search() {
  const { appLoading, setAppLoading } = useContext(AppStatusContext);

  useEffect(() => {
    setAppLoading(false);
  }, [appLoading, setAppLoading]);

  const { country } = useParams();
  const { year } = useParams();
  const { query } = useParams();

  const origins = country !== undefined ? [country] : [];
  const range = year !== undefined ? `${year}:${year}` : "";

  // Hide menu
  const toggleMenu = () => {
    document.body.classList.remove("menuOpen");
  };

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName="items"
      initialUiState={{
        items: {
          query: query ?? null,
          refinementList: {
            origins: origins,
          },
          range: {
            year: range,
          },
        },
      }}
    >
      <main className="searchResults">
        <div className="searchBox">
          <button>
            <IconSearch />
          </button>
          <SearchBox
            autoFocus
            placeholder="Find horror movies, shows, collections..."
          />
        </div>
        <CurrentRefinements clearsquery="true" />
        <NoResultsBoundary fallback={<NoResults />}>
          <InfiniteHits
            hitComponent={Hit}
            classNames={{
              disabledLoadPrevious: true,
              loadPrevious: "small",
              loadMore: "small",
            }}
          />
        </NoResultsBoundary>
        <InsightsMiddleware />
      </main>

      <div id="Menu" className="searchFilters">
        <div className="wrap">
          <h4 className="tSubtitle">Year</h4>
          <CustomRangeSlider attribute="year" />

          <h4 className="tSubtitle">Type</h4>
          <RefinementList attribute="type" />

          <h4 className="tSubtitle">Status</h4>
          <RefinementList attribute="released" />

          <h4 className="tSubtitle">Origin</h4>
          <RefinementList
            attribute="origins"
            showMore="true"
            limit={6}
            classNames={{
              showMore: "subtle small",
            }}
            translations={{
              showMoreButtonText({ isShowingMore }) {
                return isShowingMore
                  ? "Show less countries"
                  : "Show more countries";
              },
            }}
          />

          <ClearRefinements
            classNames={{
              button: "clearFilters small outline",
            }}
            translations={{
              resetButtonText: "Clear all filters",
            }}
          />
        </div>
        <div className="trap" onClick={toggleMenu}></div>
      </div>
    </InstantSearch>
  );
}
export default Search;
