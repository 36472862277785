import { MoreHorizontal } from "react-feather";

import Review from "./Review";

import "./Reviews.scss";

// Empty reviews
const EmptyReviews = ({ n }) => {
  let emptyReviews = [];
  for (let i = 0; i < n; ++i) {
    emptyReviews.push(
      <article key={i} className="Review">
        <div className="text">
          <header>
            <figure className="blurhash avatar imgPlaceholder"></figure>
            <div className="content">
              <p className="title tPlaceholder">Item title</p>
              <p className="author tSmall tPlaceholder">
                Score &middot; Author name &middot; Date
              </p>
            </div>
          </header>
          <div className="content tPlaceholderMulti"></div>
        </div>
      </article>
    );
  }

  return <>{emptyReviews}</>;
};

export default function Reviews(props) {
  return (
    <div className="Reviews preview">
      {props.reviews && props.reviews.length > 0 ? (
        <>
          <div className="items">
            {props.reviews.map((item, i) => (
              <Review
                key={i}
                total={props.total}
                review={item}
                preview={true}
              />
            ))}
          </div>
          {props.total && props.total.total > props.reviews.length && (
            <button className="icon showMore">
              <MoreHorizontal />
              <small className="tSmall">
                {props.reviews.total - props.reviews.length} more
              </small>
            </button>
          )}
        </>
      ) : (
        <div className="items">
          <EmptyReviews n="5" />
        </div>
      )}
    </div>
  );
}
